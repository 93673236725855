import { isEmpty } from 'lodash'

// Start of constants
export const flowMatcher = {
  MATCH_DEFAULT: 'MATCH_DEFAULT',
  DETRACTORS_MATCHER: 'MATCH_DETRACTORS',
  PASSIVES_MATCHER: 'MATCH_PASSIVES',
  PROMOTERS_MATCHERS: 'MATCH_PROMOTERS',
}

export const flowPriority = {
  HIGH: 100,
  NORMAL: 10,
  LOW: 1,
}

export const flowType = {
  ROOT: 'ROOT',
  INPUT: 'INPUT',
  REPORT: 'REPORT',
  FLOW_ITEM: 'FLOW_ITEM',
  FLOW_FUNCTION: 'FLOW_FUNCTION',
  ACTIVATION: 'ACTIVATION',
  ERROR: 'ERROR',
  UNKNOWN: 'UNKNOWN',
}

export const functionType = {
  RECEIVE_SMS: 'RECEIVE_SMS',
  SEND_SMS: 'SEND_SMS',
  SEND_EMAIL: 'SEND_EMAIL',
  RECEIVE_EMAIL: 'RECEIVE_EMAIL',
  SEND_HTTP_REQUEST: 'SEND_HTTP_REQUEST',
  RECEIVE_HTTP_REQUEST: 'RECEIVE_HTTP_REQUEST',
}

export const statType = {
  PROMOTERS: 'PROMOTERS',
  PASSIVES: 'PASSIVES',
  DETRACTORS: 'DETRACTORS',
  UNSUBSCRIBED: 'UNSUBSCRIBED',
  SUBSCRIBED: 'SUBSCRIBED',
  ERROR: 'ERROR',
  UNKNOWN: 'UNKNOWN',
  VERIFY: 'VERIFY',
  CHECK_VERIFICATION: 'CHECK_VERIFICATION',
}

export const detail = {
  GROUP_SUBSCRIBE: 'GROUP_SUBSCRIBE',
  GROUP_UNSUBSCRIBE: 'GROUP_UNSUBSCRIBE',
  SMS_NOTIFICATION: 'SMS_NOTIFICATION',
  EMAIL_NOTIFICATION: 'EMAIL_NOTIFICATION',
  SUBSCRIBE_RESPONSE: 'SUBSCRIBE_RESPONSE',
  UNSUBSCRIBE_RESPONSE: 'UNSUBSCRIBE_RESPONSE',
  SEND_VERIFICATION: 'SEND_VERIFICATION',
  CHECK_VERIFICATION: 'CHECK_VERIFICATION',
}

export const action = {
  SUBSCRIBE: 'SUBSCRIBE',
  UNSUBSCRIBE: 'UNSUBSCRIBE',
}

/* eslint-disable no-template-curly-in-string */
export const destinationSender = '${sender}'

// End of constants

// Start of flow API logic
export const toApi = (flowValues) => {
  return {
    flow: {
      flow_type: flowType.ROOT,
      children: flowValues,
    },
  }
}

export const addFlowChildren = (
  values,
  flowtype,
  statType,
  isChild,
  sendEmail,
  destination,
  sender
) => {
  const flowFunction = addFlowFunction(values, flowtype, destination, sender)
  let children = !isEmpty(flowFunction) ? flowFunction : undefined
  let data = null
  if (statType) {
    data = {
      ...addRecursiveFunction(values.survey, statType, isChild, sendEmail),
      flow_type: flowType.FLOW_FUNCTION,
      stat_type: statType,
    }
  }
  const flow = children
    ? { flow_functions: [{ ...children }], ...data }
    : { ...data }
  return flow
}

const addFlowFunction = (values, flowtype, destination, sender) => {
  let flowFunction = null
  let data = null
  let type = null
  if (
    flowtype === flowType.ACTIVATION &&
    (values.msisdns?.length > 0 || values.groups?.length > 0)
  ) {
    data = addSendSmsFunction(values, destination, sender)
    type = { flow_type: flowType.ACTIVATION }
  } else if (flowtype === flowType.ACTIVATION) {
    data = addReceiveSmsFunction(values)
    type = { flow_type: flowType.ACTIVATION }
  } else if (flowtype === flowType.ERROR) {
    data = addSendSmsFunction(values, destination, sender)
    type = { flow_type: flowType.FLOW_FUNCTION }
  }

  flowFunction = { ...data, ...type }
  return { ...flowFunction, ...type }
}

const addReceiveSmsFunction = (values) => {
  return {
    receivesmsfunction: { destination: values.destination },
  }
}

const addRecursiveFunction = (values, type, isChild, sendEmail) => {
  let matcher = null
  let priority = flowPriority.NORMAL

  if (sendEmail) {
    const data = addSendEmailFunction(values)
    const flowFunction = { ...data, ...type }
    return { ...flowFunction }
  } else {
    switch (type) {
      case statType.DETRACTORS:
        matcher = isChild
          ? flowMatcher.MATCH_DEFAULT
          : flowMatcher.DETRACTORS_MATCHER
        break
      case statType.PASSIVES:
        matcher = isChild
          ? flowMatcher.MATCH_DEFAULT
          : flowMatcher.PASSIVES_MATCHER
        break
      case statType.PROMOTERS:
        matcher = isChild
          ? flowMatcher.MATCH_DEFAULT
          : flowMatcher.PROMOTERS_MATCHERS
        break
      case statType.ERROR:
        matcher = flowMatcher.MATCH_DEFAULT
        priority = flowPriority.LOW
        break
      default:
        matcher = flowMatcher.MATCH_DEFAULT
        priority = flowPriority.LOW
        break
    }
    return {
      flowevent_matchers: [{ priority, matcher }],
    }
  }
}

export const addSendSmsFunction = (values, hasDestination, sender) => {
  let msisdns = []
  let groups = []
  if (values.msisdns) {
    for (let i = 0; i < values.msisdns?.length; i++) {
      msisdns.push({ phonenumber: values.msisdns[i] })
    }
  }

  if (values.groups) {
    for (let i = 0; i < values.groups?.length; i++) {
      groups.push({ group: values.groups[i] })
    }
  }

  return {
    sendsmsfunction: {
      use_session: values.use_session || false,
      sender: sender || values.destination,
      body:
        values.body ||
        values.error_message ||
        values.response ||
        values.message,
      enable_duplicates: values.enableDuplicates,
      sendsms_batches: !hasDestination
        ? [{ destination: destinationSender }]
        : [{ groups: groups, msisdns: msisdns }],
    },
  }
}

export const splitEmailValue = (emails) =>
  emails.split(',').map((value) => ({ email: value }))

export const addSendEmailFunction = (values, sender) => {
  return {
    sendemailfunction: {
      sender: destinationSender,
      subject: values.email_subject,
      body: values.email_message,
      enable_duplicates: values.enableDuplicates,
      use_session: values.use_session || false,
      sendemail_batches: [
        {
          to: splitEmailValue(values.email_to),
          cc: values.email_cc ? splitEmailValue(values.email_cc) : undefined,
          bcc: values.email_bcc ? splitEmailValue(values.email_bcc) : undefined,
        },
      ],
    },
  }
}

// End of flow API logic

// Start of flow view logic
export const fromApi = (obj) => {
  let foundActivation = null
  let errorMessage = ''
  let foundFunctions = []

  const children = obj.flow.children

  const activationMessage = children.find(({ flow_functions }) =>
    flow_functions.find(({ sendsmsfunction }) => sendsmsfunction)
  )

  if (activationMessage) {
    const data = activationMessage.flow_functions.find(
      ({ sendsmsfunction }) => sendsmsfunction
    )
    if (data) {
      const sendData = data.sendsmsfunction?.sendsms_batches[0]
      const phoneNumbers = []
      if (sendData.msisdns.length > 0) {
        for (let i = 0; i < sendData.msisdns.length; i++) {
          phoneNumbers.push(sendData.msisdns[i].phonenumber)
        }
      }
      const groups = []
      if (sendData.groups.length > 0) {
        for (let i = 0; i < sendData.groups.length; i++) {
          groups.push(sendData.groups[i].group)
        }
      }
      foundActivation = {
        ...sendData,
        body: data.sendsmsfunction.body,
        msisdns: phoneNumbers,
        groups: groups,
      }
    }
  }

  const recursiveParts = children.find(({ children }) => children.length > 0)
  if (recursiveParts) {
    const part = recursiveParts.children
    for (let j = 0; j < part.length; j++) {
      if (part[j].stat_type !== statType.ERROR) {
        foundFunctions[j] = {
          response:
            part[j].flow_functions?.length > 0
              ? part[j].flow_functions[0].sendsmsfunction?.body
              : null,
          children: [
            {
              sms_action:
                part[j].children?.length > 0
                  ? part[j].children[0].flow_functions[1] &&
                    part[j].children[0].flow_functions[1].detail ===
                      detail.SMS_NOTIFICATION
                    ? setFlowNotification(
                        part[j].children[0].flow_functions[1],
                        detail.SMS_NOTIFICATION
                      )
                    : part[j].children[0].flow_functions[2] &&
                      part[j].children[0].flow_functions[2].detail ===
                        detail.SMS_NOTIFICATION
                    ? setFlowNotification(
                        part[j].children[0].flow_functions[2],
                        detail.SMS_NOTIFICATION
                      )
                    : setFlowNotification(
                        part[j].children[0].flow_functions[0],
                        detail.SMS_NOTIFICATION
                      )
                  : undefined,
              isActive: part[j].children?.length > 0,
              email_action:
                part[j].children?.length > 0
                  ? part[j].children[0].flow_functions[1] &&
                    part[j].children[0].flow_functions[1].detail ===
                      detail.EMAIL_NOTIFICATION
                    ? setFlowNotification(
                        part[j].children[0].flow_functions[1],
                        detail.EMAIL_NOTIFICATION
                      )
                    : part[j].children[0].flow_functions[2]
                    ? setFlowNotification(
                        part[j].children[0].flow_functions[2],
                        detail.EMAIL_NOTIFICATION
                      )
                    : setFlowNotification(
                        part[j].children[0].flow_functions[0],
                        detail.EMAIL_NOTIFICATION
                      )
                  : undefined,
              response:
                part[j].children &&
                part[j].children[0] &&
                part[j].children[0].flow_functions.length > 0 &&
                part[j].children[0].flow_functions[0].detail !==
                  detail.SMS_NOTIFICATION
                  ? part[j].children[0].flow_functions[0].sendsmsfunction?.body
                  : null,
            },
          ],
          sms_action:
            part[j].flow_functions &&
            part[j].flow_functions[1] &&
            part[j].flow_functions[1].detail === detail.SMS_NOTIFICATION
              ? setFlowNotification(
                  part[j].flow_functions[1],
                  detail.SMS_NOTIFICATION
                )
              : part[j].flow_functions[2] &&
                part[j].flow_functions[2].detail === detail.SMS_NOTIFICATION
              ? setFlowNotification(
                  part[j].flow_functions[2],
                  detail.SMS_NOTIFICATION
                )
              : setFlowNotification(
                  part[j].flow_functions[0],
                  detail.SMS_NOTIFICATION
                ),
          email_action:
            part[j].flow_functions &&
            part[j].flow_functions[1] &&
            part[j].flow_functions[1].detail === detail.EMAIL_NOTIFICATION
              ? setFlowNotification(
                  part[j].flow_functions[1],
                  detail.EMAIL_NOTIFICATION
                )
              : part[j].flow_functions[2] &&
                part[j].flow_functions[2].detail === detail.EMAIL_NOTIFICATION
              ? setFlowNotification(
                  part[j].flow_functions[2],
                  detail.EMAIL_NOTIFICATION
                )
              : setFlowNotification(
                  part[j].flow_functions[0],
                  detail.EMAIL_NOTIFICATION
                ),
          destination: '',
        }
      } else if (part[j].flow_functions && part[j].flow_functions.length > 0) {
        errorMessage = part[j].flow_functions[0].sendsmsfunction?.body
      }
    }
  }

  return {
    payload: obj,
    parsed: {
      name: obj.application.name,
      systemapplication_id: obj.application.systemapplication_id,
      applicationevent_key: obj.application.applicationevent_key,
      created: obj.application.created,
      end_datetime: obj.application.end,
      start_datetime: obj.application.begin,
      api_key: obj.application.apikey,
      error_message: errorMessage,
      is_error_message: !!errorMessage,
      status: obj.application.status,
      activation_message: foundActivation,
      survey: foundFunctions,
      destination:
        recursiveParts.flow_functions[0].receivesmsfunction?.destination,
    },
  }
}

const setFlowNotification = (values, type) => {
  switch (values?.detail) {
    case detail.SMS_NOTIFICATION:
      const parsedSms =
        values?.sendsmsfunction && type === values?.detail
          ? setSmsNotification(values.sendsmsfunction)
          : undefined
      return parsedSms
    case detail.EMAIL_NOTIFICATION:
      const parsedEmail =
        values?.sendemailfunction && type === values?.detail
          ? setEmailNotification(values.sendemailfunction)
          : undefined
      return parsedEmail
    default:
      return undefined
  }
}

const setSmsNotification = (values) => {
  const groups = []
  const msisdns = []

  if (values.sendsms_batches?.length > 0) {
    const sendData = values.sendsms_batches[0]
    if (sendData.groups?.length > 0) {
      for (let i = 0; i < sendData.groups.length; i++) {
        groups.push(sendData.groups[i].group)
      }
    }
    if (sendData.msisdns?.length > 0) {
      for (let i = 0; i < sendData.msisdns.length; i++) {
        msisdns.push(sendData.msisdns[i].phonenumber)
      }
    }
  }

  const data =
    values.sendsms_batches?.length > 0
      ? {
          smsfrom: values.sender,
          msisdns: msisdns,
          groups: groups,
          message: values.body || '',
          notificationgroup: false,
        }
      : undefined
  return data
}

const setEmailNotification = (values) => {
  let to = ''
  let cc = ''
  let bcc = ''

  if (values.sendemail_batches?.length > 0) {
    const emailData = values.sendemail_batches[0]
    if (emailData.to?.length > 0) {
      for (let i = 0; i < emailData.to.length; i++) {
        to += emailData.to[i].email + ','
      }
    }
    if (emailData.cc?.length > 0) {
      for (let i = 0; i < emailData.cc.length; i++) {
        cc += emailData.cc[i].email + ','
      }
    }
    if (emailData.bcc?.length > 0) {
      for (let i = 0; i < emailData.bcc.length; i++) {
        bcc += emailData.bcc[i].email + ','
      }
    }
  }
  return {
    email_from: values.from || '',
    email_to: to,
    email_cc: cc,
    email_bcc: bcc,
    email_subject: values.subject || '',
    email_message: values.body || '',
    email_to_notification: false, // not used in flow
    email_cc_notification: false, // not used in flow
    email_bcc_notification: false, // not used in flow
  }
}

// End of flow view logic
