import { merge } from 'lodash'
import moment from 'moment'

import { fileDownloader } from '@common/utils'
import { setApplicationDefaultValues } from 'app/api/API'
import { hasActivationMessage } from 'app/common/editor/utils'
import {
  addFlowChildren,
  addSendEmailFunction,
  addSendSmsFunction,
  detail,
  flowType,
  fromApi,
  statType,
  toApi,
} from '../FlowAdapter'

export const parseNpsSurvey = (values, activationBody) => {
  let flowEvents = null
  const surveyFlows = [
    statType.DETRACTORS,
    statType.PASSIVES,
    statType.PROMOTERS,
  ]
  const sender = values.destination

  if (hasActivationMessage(values)) {
    values.activation_message.body = activationBody
    // Activation message
    flowEvents = {
      flow_type: flowType.ACTIVATION,
      ...addFlowChildren(
        values.activation_message,
        flowType.ACTIVATION,
        '',
        false,
        false,
        values.destination,
        sender
      ),
    }
  }

  let survey =
    //activation receiving
    {
      ...addFlowChildren(
        values,
        flowType.ACTIVATION,
        '',
        false,
        false,
        values.destination,
        sender
      ),
      flow_type: flowType.ACTIVATION,
    }
  survey.children = []
  for (let i = 0; i < values.survey.length; i++) {
    const data = values.survey[i]

    survey.children.push({
      ...setNewLevel(data, flowType.INPUT, surveyFlows[i], false, '', sender),
      flow_type: flowType.FLOW_ITEM,
    })

    // Checks children inside each survey
    if (data.children.length > 0 && data.children[0].isActive) {
      for (let j = 0; j < data.children.length; j++) {
        const childrenData = data.children[j]
        survey.children[i].children = []
        survey.children[i].children.push({
          ...setNewLevel(
            childrenData,
            flowType.FLOW_ITEM,
            statType.UNKNOWN,
            true,
            '',
            sender
          ),
          flow_type: flowType.FLOW_ITEM,
        })
      }
    }
  }

  if (values.is_error_message) {
    //error message
    survey.children.push({
      ...addFlowChildren(
        values,
        flowType.ERROR,
        statType.ERROR,
        false,
        false,
        '',
        sender
      ),
      stat_type: flowType.ERROR,
      flow_type: flowType.FLOW_ITEM,
    })
  }
  const payload = flowEvents
    ? [{ ...survey }, { ...flowEvents }]
    : [{ ...survey }]
  return toApi(payload)
}

export const setNewLevel = (
  values,
  flowType,
  level,
  isChild,
  destination,
  sender
) => {
  let flowFunctions = null

  const addSending = values.response
    ? addSendSmsFunction(values, destination, sender)
    : undefined
  flowFunctions = {
    ...addFlowChildren(
      values,
      flowType,
      level,
      isChild,
      false,
      destination,
      sender
    ),
    flow_functions: addSending ? [{ ...addSending }] : [],
    flow_type: flowType.FLOW_FUNCTION,
  }
  if (values.sms_action?.smsfrom) {
    flowFunctions.flow_functions.push({
      ...addSendSmsFunction(values.sms_action, sender, sender),
      flow_type: flowType.FLOW_FUNCTION,
      detail: detail.SMS_NOTIFICATION,
    })
  }
  if (values.email_action?.email_from) {
    const addEmail = addSendEmailFunction(values.email_action, sender)
    flowFunctions.flow_functions.push({
      ...addEmail,
      flow_type: flowType.FLOW_FUNCTION,
      detail: detail.EMAIL_NOTIFICATION,
    })
  }

  return { ...flowFunctions, flow_type: flowType.FLOW_FUNCTION }
}

export class NpsSurvey {
  constructor(api) {
    this.api = api
    this.endpointUrl = 'app/npssurvey'
    this.abortUrl = 'app/abort'
  }

  get(request) {
    const { id, customerId } = request
    return this.api
      .get(`${this.endpointUrl}/${id}`, {}, true, {
        'X-Customer': customerId,
      })
      .then((response) => fromApi(response))
  }

  post(request) {
    const { customerId, application, flow } = request
    const payload = { application, flow }

    return this.api
      .post(`${this.endpointUrl}`, payload, true, {
        'X-Customer': customerId,
      })
      .then(() => {
        return {
          success: true,
        }
      })
  }

  update(request) {
    const { id, customerId, data, flow } = request

    const applicationValues = setApplicationDefaultValues(
      data.name,
      data.systemapplication_id,
      data.applicationevent_key,
      data.is_receivedatetime
        ? moment(
            data.receive_enddate + ' ' + data.receive_endtime,
            'DD.MM.YYYY HH:mm'
          ).format('YYYY-MM-DDTHH:mm:ssZ')
        : null,
      data.is_scheduled
        ? moment(
            data.scheduled_startdate + ' ' + data.scheduled_starttime,
            'DD.MM.YYYY HH:mm'
          ).format('YYYY-MM-DDTHH:mm:ssZ')
        : null,
      parseInt(id),
      data
    )

    const parsed = parseNpsSurvey(data, data.activation_message?.body)
    const surveyChildren = parsed.flow.children[0].children
    const flowChildren = flow.flow.children[0].children

    if (surveyChildren.length < flowChildren.length) {
      flow.flow.children[0].children.pop()
    }

    const merged = merge(flow, parsed)
    const combined = { ...merged, ...applicationValues }

    return this.api
      .put(
        `${this.endpointUrl}/${id}`,
        {
          ...combined,
        },
        true,
        {
          'X-Customer': customerId,
        }
      )
      .then((response) => {
        return response
      })
  }

  abort(request) {
    const { id, customerId } = request

    return this.api
      .get(`${this.abortUrl}/${id}`, {}, true, {
        'X-Customer': customerId,
      })
      .then(() => {
        return true
      })
  }
  delete(request) {
    const { id, customerId } = request
    return this.api
      .delete(`${this.endpointUrl}/${id}`, {}, true, {
        'X-Customer': customerId,
      })
      .then(() => {
        return {
          success: true,
        }
      })
  }

  getReports = (request = {}) => {
    const {
      extra: {
        customerId,
        id,
        columns,
        download,
        filterInput: { reportDaterange, number, onLoad },
      },
    } = request

    const options = {
      ...request,
      search: {
        ...request.search,
        showcolumns: columns || '',
        reportDaterange: !onLoad
          ? `${reportDaterange.from} - ${reportDaterange.to}`
          : {},
        number,
        download,
      },
    }

    return this.api
      .get(
        `${this.endpointUrl}report/${id}`,
        {},
        true,
        {
          'X-Customer': customerId,
          ...this.api.withFilters(options, {
            reportDaterange: 'report_daterange',
            number: 'msisdn',
            contact__msisdn: 'contact__msisdn',
            itemmessage: 'itemmessage',
            response: 'response',
            created: 'created',
            showcolumns: 'showcolumns',
            download: 'download',
          }),
        },
        download === 'excel' ? 'true' : false
      )
      .then((result) => {
        const {
          responses,
          count,
          report_daterange,
          showcolumns,
          guicolumns,
          rawreportcolumns,
        } = result

        if (download !== '') {
          fileDownloader({
            result,
            filename: `dialogreport`,
            type: download,
          })
          return this.getReports({
            ...request,
            extra: { ...request.extra, download: '' },
          })
        }

        return {
          count,
          results: responses,
          additional: {
            rawreportcolumns,
            report_daterange,
            showcolumns,
            guicolumns,
          },
        }
      })
  }

  getStatistics(request) {
    const { id, customerId, dateRange, dateResolution, comparedRange } = request
    return this.api
      .get(`${this.endpointUrl}statistics/${id}`, {}, true, {
        'X-Customer': customerId,
        'X-filter': JSON.stringify({
          statistics_daterange: dateRange,
          statistics_dateresolution: dateResolution,
          compared_daterange: comparedRange,
        }),
      })
      .then((response) => {
        return {
          ...response,
        }
      })
  }
}
