/**
 * Config
 */

const environment =
  process.env.PUBLIC_URL ||
  process.env.REACT_APP_PUBLIC_URL ||
  'http://localhost:8000'

const config = {
  api: {
    base: environment + '/api/',
    //base: 'http://localhost:8000/api/',
    version: 'v1',
  },
  lang: {
    default: 'en',
    fallback: 'en',
  },
  log: {
    redux: false,
  },
  test: {
    user: {
      email: 'ronald@iona.studio',
      firstName: 'Ronald',
      language: 'en',
      lastName: 'Borla',
      mobilePhone: '(+63) 917 577 7478',
      password: 'VdEUgaw9N2',
    },
  },
}

export default config
