import { isFunction } from 'lodash'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { appBusy } from '../actions'
import { actionFail, actionName, actionSuccess } from '../common/action'
import {
  formAction,
  formBusy,
  formErrorMessage,
  formFail,
  formSuccess,
} from '../common/form'
import config from '../config'
import { API } from './API'

/**
 * API instance
 */
const api = new API(config.api || {})

export const ssoconnection = api.ssoconnection
export const billing = api.billing
export const contact = api.contact
export const customer = api.customer
export const dashboard = api.dashboard
export const log = api.log
export const message = api.message
export const moNumber = api.moNumber
export const policy = api.policy
export const router = api.router
export const tracking = api.tracking
export const user = api.user
export const settingsUser = api.settingsUser
export const services = api.services
export const configurations = api.configurations
export const newsfeed = api.newsfeed
export const integrations = api.integrations
export const apiKeys = api.apiKeys
export const cleanup = api.cleanup

/**
 * Create API call saga
 */
export function createActionSaga(action, request, options = {}) {
  return function* watchAPIRequest() {
    yield takeEvery(
      actionName(action),
      function* handleAPIRequest({ payload }) {
        if (options.busy === true || options.busy === 'app') {
          yield put(appBusy(true))
        } else if (options.busy === 'form') {
          yield put(formBusy(true))
        }
        try {
          if (isFunction(options.args)) {
            payload = options.args(payload)
          }
          if (isFunction(payload)) {
            payload = yield select(payload)
          }
          const result = yield call(request, payload)
          if (isFunction(options.success)) {
            yield call(options.success, result)
          }
          yield actionSuccess(action, result)
        } catch (exception) {
          yield actionFail(action, exception)
        }
        if (options.busy === true || options.busy === 'app') {
          yield put(appBusy(false))
        } else if (options.busy === 'form') {
          yield put(formBusy(false))
        }
      }
    )
  }
}

/**
 * Create form saga
 */
export function createFormSaga(action, request, options = {}) {
  return function* watchFormRequest() {
    yield takeEvery(
      formAction(action),
      function* handleFormRequest({ payload }) {
        if (options.busy === true || options.busy === 'app') {
          yield put(appBusy(true))
        } else if (options.busy === 'form') {
          yield put(formBusy(true))
        }
        try {
          if (isFunction(options.args)) {
            payload = options.args(payload)
          }
          if (isFunction(payload)) {
            payload = yield select(payload)
          }
          const result = yield call(request, payload)
          if (isFunction(options.success)) {
            yield call(options.success, result)
          }
          yield formSuccess(
            action,
            result,
            (isFunction(options.message)
              ? options.message(payload, result)
              : options.message) || '',
            isFunction(options.redirect)
              ? options.redirect(payload, result)
              : options.redirect
          )
        } catch (error) {
          yield formFail(action, formErrorMessage(error))
        }
        if (options.busy === true || options.busy === 'app') {
          yield put(appBusy(false))
        } else if (options.busy === 'form') {
          yield put(formBusy(false))
        }
      }
    )
  }
}

export function getPageNumber(options = {}) {
  return (options.offset || 0) / (options.limit || 25) + 1
}

export function getTableQueryHeaders(options) {
  const filters = {
    ...options.filters,
    ...options.filters?.search,
  }
  delete filters.search
  let headers = {
    'X-Filter': JSON.stringify(filters),
  }
  if (options.customerId) {
    headers = {
      ...headers,
      'X-Customer': options.customerId,
    }
  }
  return headers
}

export default api
