/**
 * Tag strategy
 */
export function tagStrategy(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity()
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'tag'
    )
  }, callback)
}

/**
 * Get valid tags
 */
export function getValidTags(block, tags, prefix, suffix) {
  const length = tags.length,
    text = block.getText(),
    positions = []
  let next = 0
  while (true) {
    const start = text.indexOf(prefix, next)
    if (start < 0) {
      break
    }
    for (let i = 0; i < length; i++) {
      const match = prefix + tags[i] + suffix,
        end = start + match.length,
        substr = text.substring(start, end)
      if (match === substr) {
        if (!block.getEntityAt(start)) {
          positions.push({
            start,
            end,
          })
        }
        next = end
        break
      } else if (i === length - 1) {
        next = start + 1
      }
    }
  }
  return positions
}

export function hasActivationMessage(data) {
  return (
    data.activation_message?.groups?.length > 0 ||
    data.activation_message?.msisdns?.length > 0
  )
}
