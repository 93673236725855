import { parseIntIfNotNumber } from 'app/common/utils'
import { logMasks } from '../Customer'
import { Serializer } from './Serializer'

export enum CompanyStatus {
  ACTIVE = 'ACTIVE',
  PASSIVE = 'PASSIVE',
  DELETED = 'DELETED',
}

export enum DemoStatus {
  PRODUCTION = 'PRODUCTION',
  DEMO = 'DEMO',
  ACTIVATION_REQUESTED = 'ACTIVATION_REQUESTED',
}

export interface CompanyIn {
  id: string
  name: string
  licensecustomer: boolean
  vatcode: string
  extid: string
  status: CompanyStatus
  demo: DemoStatus
  filtering: boolean
  mtlimit: number
  sender: string
  sendertype: string
  country_code: number
  maskmsisdn: boolean
  maskmessage: boolean
  throttle: number
  validityperiod: number
  info: string
  created: string
  billing_interval: number
  payment_term: number
  our_reference: string | null
  your_reference: string | null
  external_info: string | null
}

export type Company = Pick<
  CompanyIn,
  'name' | 'info' | 'status' | 'filtering' | 'created'
> & {
  id?: string | number
  license: CompanyIn['licensecustomer']
  vatCode: CompanyIn['vatcode']
  externalId: CompanyIn['extid']
  demoStatus: DemoStatus
  messageLimit: number | string
  messageThrottle: CompanyIn['throttle']
  messageValidityPeriod: CompanyIn['validityperiod']
  defaultSender: CompanyIn['sender']
  countryCode: CompanyIn['country_code']
  logMask: number
  billingInterval: CompanyIn['billing_interval']
  ourReference: CompanyIn['our_reference']
  yourReference: CompanyIn['your_reference']
  paymentTerm: CompanyIn['payment_term']
  externalInfo: CompanyIn['external_info']
}

export type CompanyOut = Omit<
  CompanyIn,
  'id' | 'licensecustomer' | 'created'
> & {
  licensedata: any
}

class CompanyIO extends Serializer<CompanyIn, Company, CompanyOut> {
  getLogMask = (data: { maskmsisdn?: boolean; maskmessage?: boolean }) => {
    return (
      (data.maskmsisdn ? logMasks.msisdn : 0) +
      (data.maskmessage ? logMasks.message : 0)
    )
  };

  in = (data: CompanyIn) => {
    const customer: Company = {
      id: data.id + '',
      license: data.licensecustomer,
      name: data.name,
      vatCode: data.vatcode,
      info: data.info,
      externalId: data.extid || '',
      status: data.status,
      demoStatus: data.demo,
      filtering: data.filtering,
      messageLimit: data.mtlimit,
      messageThrottle: data.throttle,
      messageValidityPeriod: data.validityperiod,
      defaultSender: data.sender,
      countryCode: data.country_code,
      logMask: this.getLogMask(data),
      created: data.created,
      billingInterval: data.billing_interval,
      paymentTerm: data.payment_term,
      ourReference: data.our_reference || '',
      yourReference: data.your_reference || '',
      externalInfo: data.external_info || '',
    }
    return customer
  }

  out = (data: Company) => {
    const payload: CompanyOut = {
      name: data.name,
      licensedata: !!data.license,
      vatcode: data.vatCode,
      info: data.info,
      extid: data.externalId || '',
      status: data.status,
      demo: data.demoStatus,
      filtering: data.filtering,
      mtlimit: parseIntIfNotNumber(data.messageLimit),
      sender: data.defaultSender,
      sendertype: 'ALNUM',
      country_code: parseIntIfNotNumber(data.countryCode),
      maskmsisdn: !!(data.logMask & logMasks.msisdn),
      maskmessage: !!(data.logMask & logMasks.message),
      throttle: parseIntIfNotNumber(data.messageThrottle),
      validityperiod: parseIntIfNotNumber(data.messageValidityPeriod),
      billing_interval: parseIntIfNotNumber(data.billingInterval),
      payment_term: parseIntIfNotNumber(data.paymentTerm),
      our_reference: data.ourReference || null,
      your_reference: data.yourReference || null,
      external_info: data.externalInfo || null,
    }
    return payload
  }
}

export default new CompanyIO()
