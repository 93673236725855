import axios from 'axios'

import {
  ApiKey,
  ApiKeyPostData,
  ApiKeyPutData,
  SinglePurposeApiKey,
  TableRequestOptions,
  TableResponse,
} from 'app/types/app'
import { API } from './API'
import { getPageNumber } from '.'

export class ApiKeys {
  api: API
  globalKeyUrl = 'service/apikeys/global'
  singleUseKeyUrl = 'service/apikeys/singleuse'
  constructor(api: API) {
    this.api = api
  }

  createApiKey = (
    data: ApiKeyPostData,
    options: { customerId: number }
  ): Promise<ApiKey> => {
    const headers = {
      'X-Customer': options.customerId,
      'Content-Type': 'application/json',
    }
    return this.api.post(this.globalKeyUrl, data, true, headers)
  }

  updateApiKey = (
    id: number,
    data: ApiKeyPutData,
    options: { customerId: number }
  ): Promise<ApiKey> => {
    const headers = {
      'X-Customer': options.customerId,
      'Content-Type': 'application/json',
    }
    return this.api.put(`${this.globalKeyUrl}/${id}`, data, true, headers)
  }

  getGlobalApiKeys = async (options: TableRequestOptions) => {
    const filters = {
      ...options.filters,
      ...options.filters?.search,
    }
    delete filters.search

    return this.api.get(
      `${this.globalKeyUrl}?page=${getPageNumber(options)}`,
      {},
      true,
      {
        'X-Customer': options.customerId,
        'X-Filter': JSON.stringify(filters),
      }
    ) as TableResponse<ApiKey>
  }

  getSingleUseApiKeys = async (options: TableRequestOptions) => {
    const filters = {
      ...options.filters,
      ...options.filters?.search,
    }
    delete filters.search

    return this.api.get(
      `${this.singleUseKeyUrl}?page=${getPageNumber(options)}`,
      {},
      true,
      {
        'X-Customer': options.customerId,
        'X-Filter': JSON.stringify(filters),
      }
    ) as TableResponse<SinglePurposeApiKey>
  }

  getApiKey = async (
    apikey: string,
    options: {
      customerId: number
    }
  ): Promise<ApiKey> => {
    return this.api.get(`service/apikeys/global/${apikey}`, {}, true, {
      'X-Customer': options.customerId,
    })
  }

  deleteApiKey = async (id: number, options: { customerId: number }) => {
    const headers = {
      'X-Customer': options.customerId,
    }
    try {
      return await this.api.delete(
        `service/apikeys/global/${id}`,
        {},
        true,
        headers
      )
    } catch (error) {
      if (!axios.isAxiosError(error) || error.response?.status !== 404) {
        throw error
      }
    }
  }
}
