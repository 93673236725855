import { isUndefined, upperFirst } from 'lodash'

import { bind } from '../common/utils'
import { fileDownloader } from '../common/utils'
import { getPageNumber } from '.'

export const SMS_CHARACTER_LIMIT = 160

/**
 * Messaging applicatiosn
 */
export const messagingApplications = {
  groupMessage: 'Group message',
  feedbackAndInquiry: 'Feedback & Inquiry',
  quickMessage: 'Quick message',
  dialog: 'Dialog',
  callToAction: 'Call to action',
  inbox: 'Inbox',
}

/**
 * Event statuses
 */
export const eventStatuses = {
  INITIALIZING: 'Initializing',
  CREATED: 'Created',
  RUNNING: 'Running',
  ABORTED: 'Aborted',
  FAILED: 'Failed',
  FINISHED: 'Finished',
}

export const taskTypes = {
  SEND_MESSAGE: 'Send message',
  SEND_REMIND_MESSAGE: 'Send remind message',
}

/**
 * Message API
 */
export class Message {
  constructor(api) {
    this.api = api
    bind(this, [
      'deleteEvent',
      'deleteTemplate',
      'getApplications',
      'getEvents',
      'getNumbers',
      'getTasks',
      'getTemplate',
      'getTemplates',
      'getMessages',
      'getMessageColumns',
      'getEventsColumns',
      'saveTemplate',
      'toggleApplication',
    ])
    this.templates = []
    this.lastTemplateId = 0
  }

  /**
   * Delete event
   */
  deleteEvent({ id, customerId, options }) {
    if (isUndefined(id)) {
      return this.api
        .delete(`service/events`, {}, true, {
          'X-Customer': customerId,
          ...this.api.withFilters(options, {
            applicationeventid: 'selected',
            contactid: 'contactid',
            groupid: 'groupid',
            all: 'all',
          }),
        })
        .then(() => {
          return {
            success: true,
          }
        })
    } else {
      return this.api
        .delete(`service/event/${id}`, {}, true, {
          'X-Customer': customerId,
        })
        .then(() => {
          return {
            success: true,
          }
        })
    }
  }

  deleteTemplate({ id, customerId, options }) {
    return this.api
      .delete(`service/messagetemplates`, {}, true, {
        'X-Customer': customerId,
        ...this.api.withFilters(options, {
          selected: 'selected',
          all: 'all',
        }),
      })
      .then(() => {
        return {
          success: true,
        }
      })
  }

  /**
   * Generate key
   */
  generateKey({ customerId }) {
    return this.api
      .get(`app/apikey`, {}, true, {
        'X-Customer': customerId,
      })
      .then((response) => {
        return response.key
      })
  }

  /**
   * Get all applications
   */
  getApplications({ customerId }) {
    const response = (app) => {
      return {
        id: app.id,
        tags: [app.channels],
        description: [app.short_description],
        title: app.name,
        name: app.modulename,
        icon: app.icon,
      }
    }

    return this.api
      .get('app/applications', {}, true, {
        'X-Customer': customerId,
      })
      .then(({ all, available, favorites }) => {
        return {
          all: all.map(response),
          available: available.map((app) => {
            return {
              id: app.id,
              tags: [app.channels],
              description: [app.short_description],
              title: app.name,
              name: app.modulename,
              icon: app.icon,
              active: false,
            }
          }),
          favorites: favorites.map((app) => {
            return {
              id: app.id,
              tags: [app.channels],
              description: [app.short_description],
              title: app.name,
              name: app.modulename,
              icon: app.icon,
              active: true,
            }
          }),
        }
      })
  }

  getApplicationByModuleName({ modulename, customerId }) {
    return this.api
      .get(`app/systemapplication/${modulename}`, {}, true, {
        'X-Customer': customerId,
      })
      .then((response) => response)
  }

  /**
   * Toggle application
   */
  toggleApplication({ active, id, customerId }) {
    return this.api
      .put(
        `app/application/${id}`,
        {
          is_favorite: active,
        },
        true,
        {
          'X-Customer': customerId,
        }
      )
      .then(() => {
        return {
          success: true,
        }
      })
  }

  /**
   * Get events
   */
  getEvents(request = {}) {
    const {
      extra: { customerId, contactid, groupid },
    } = request
    const options = {
      ...request,
      search: {
        ...request.search,
        contactid,
        groupid,
      },
    }
    return this.api
      .get('service/events?page=' + getPageNumber(options), {}, true, {
        'X-Customer': customerId,
        ...this.api.withFilters(options, {
          contactid: 'contactid',
          groupid: 'groupid',
          systemapplication__id: 'systemapplication__id',
          name: 'name',
          description: 'description',
          status: 'status',
          created_range: 'created_range',
          created: 'created',
        }),
      })
      .then(({ count, results }) => {
        return {
          count,
          results: results.map((result) => {
            const info = {
              receiveInfo: result.receiveinfo || {},
              sendInfo: result.sendinfo || {},
            }
            return {
              id: result.id,
              systemapplication__id: result.systemapplication,
              name: result.name,
              description: result.description,
              status: upperFirst(result.status.toLowerCase()),
              info,
              created: result.created,
            }
          }),
        }
      })
  }

  /**
   * Get numbers
   */
  getNumbers(request) {
    const { customerId } = request
    return this.api
      .get('service/customermonumbers', {}, true, {
        'X-Customer': customerId,
      })
      .then(({ monumbers }) => {
        return monumbers.map((result) => [
          result.number,
          Boolean(result.routeruleinfo.length),
        ])
      })
  }

  getTemplate({ id, customerId }) {
    return this.api
      .get(`service/messagetemplate/${id}`, {}, true, {
        'X-Customer': customerId,
      })
      .then((result) => result)
  }

  getTemplates(options = {}) {
    return this.api
      .get(
        'service/messagetemplates?page=' + getPageNumber(options),
        {},
        true,
        {
          'X-Customer': options.extra.customerId,
          ...this.api.withFilters(options, {
            name: 'name',
            message: 'message',
            created: 'created',
            type: 'type',
          }),
        }
      )
      .then(({ count, results }) => {
        return {
          count,
          results,
        }
      })
  }

  saveTemplate(template) {
    const headers = {
      'X-Customer': template.customerId,
    }
    if (template.id) {
      return this.api
        .put(
          `service/messagetemplate/${template.id}`,
          {
            name: template.name,
            message: template.message,
            apiuse: template.apiuse,
            type: template.type,
            default: template.default,
          },
          true,
          headers
        )
        .then((result) => {
          return {
            id: result.id,
          }
        })
    } else {
      return this.api
        .post(
          'service/messagetemplates',
          {
            name: template.name,
            message: template.message,
            apiuse: template.apiuse,
            type: template.type,
            default: template.default,
          },
          true,
          headers
        )
        .then((result) => {
          return {
            id: result.id,
          }
        })
    }
  }

  getTasks(request) {
    const {
      extra: { customerId, applicationeventid, contactid, groupid, columns },
    } = request
    const options = {
      ...request,
      search: {
        ...request.search,
        applicationeventid,
        contactid,
        groupid,
        showcolumns: columns || [],
      },
    }

    return this.api
      .get('app/tasks?page=' + getPageNumber(options), {}, true, {
        'X-Customer': customerId,
        ...this.api.withFilters(options, {
          applicationeventid: 'applicationeventid',
          contactid: 'contactid',
          groupid: 'groupid',
          status: 'status',
          tasktype: 'tasktype',
          taskdatetime_range: 'taskdatetime_range',
          showcolumns: 'showcolumns',
        }),
      })
      .then(
        ({ count, responses, taskdatetime_range, showcolumns, guicolumns }) => {
          return {
            count,
            results: responses,
            showcolumns: {
              guicolumns,
              showcolumns,
            },
            taskDatetimeRange: taskdatetime_range,
          }
        }
      )
  }

  getMessages(request) {
    const {
      extra: { customerId, eventid, contactid, groupid, created_range },
    } = request
    let options = {}
    if (!isUndefined(created_range)) {
      options = {
        ...request,
        search: {
          ...request.search,
          eventid,
          contactid,
          groupid,
          created_range,
        },
      }
    } else {
      options = {
        ...request,
        search: {
          ...request.search,
          eventid,
          contactid,
          groupid,
        },
      }
    }

    return this.api
      .get('service/messages?page=' + getPageNumber(options), {}, true, {
        'X-Customer': customerId,
        ...this.api.withFilters(options, {
          eventid: 'eventid',
          contactid: 'contactid',
          groupid: 'groupid',
          billingref: 'billingref',
          body: 'body',
          bodyparts: 'bodyparts',
          bodytype: 'bodytype',
          countrycode: 'countrycode',
          created_range: 'created_range',
          destination: 'destination',
          direction: 'direction',
          reason: 'reason',
          sender: 'sender',
          sendertype: 'sendertype',
          sessionmessages: 'sessionmessages',
          status: 'status',
          contact__id: 'contact__id',
          contact__msisdn: 'contact__msisdn',
          contact__address: 'contact__address',
          contact__city: 'contact__city',
          contact__customernumber: 'contact__customernumber',
          contact__email: 'contact__email',
          contact__first_name: 'contact__first_name',
          contact__last_name: 'contact__last_name',
          contact__param1: 'contact__param1',
          contact__param2: 'contact__param2',
          contact__param3: 'contact__param3',
          contact__param4: 'contact__param4',
          contact__phone: 'contact__phone',
          contact__postalcode: 'contact__postalcode',
          applicationsession__extid: 'applicationsession__extid',
          applicationsession__extkey: 'applicationsession__extkey',
          applicationsession__address: 'applicationsession__address',
          applicationsession__city: 'applicationsession__city',
          applicationsession__customernumber:
            'applicationsession__customernumber',
          applicationsession__email: 'applicationsession__email',
          applicationsession__first_name: 'applicationsession__first_name',
          applicationsession__last_name: 'applicationsession__last_name',
          applicationsession__param1: 'applicationsession__param1',
          applicationsession__param2: 'applicationsession__param2',
          applicationsession__param3: 'applicationsession__param3',
          applicationsession__param4: 'applicationsession__param4',
          applicationsession__phone: 'applicationsession__phone',
          applicationsession__postalcode: 'applicationsession__postalcode',
          drid: 'drid',
        }),
      })
      .then(({ count, results }) => {
        const response = results.map((result) => {
          return {
            ...result,
            applicationevent__name: result.applicationevent.name,
            sessionmessages: result.sessionmessages.length > 0 ? 'Yes' : 'No',
            applicationevent: '',
            contact: '',
            contact__id: result.contact.id,
            contact__msisdn: result.contact.msisdn,
            contact__address: result.contact.address,
            contact__city: result.contact.city,
            contact__customernumber: result.contact.customernumber,
            contact__email: result.contact.email,
            contact__first_name: result.contact.first_name,
            contact__last_name: result.contact.last_name,
            contact__param1: result.contact.param1,
            contact__param2: result.contact.param2,
            contact__param3: result.contact.param3,
            contact__param4: result.contact.param4,
            contact__phone: result.contact.phone,
            contact__postalcode: result.contact.postalcode,
            applicationsession__extid: result.applicationsession?.extid,
            applicationsession__extkey: result.applicationsession?.extkey,
            applicationsession__address: result.applicationsession?.address,
            applicationsession__city: result.applicationsession?.city,
            applicationsession__customernumber:
              result.applicationsession?.customernumber,
            applicationsession__email: result.applicationsession?.email,
            applicationsession__first_name:
              result.applicationsession?.first_name,
            applicationsession__last_name: result.applicationsession?.last_name,
            applicationsession__param1: result.applicationsession?.param1,
            applicationsession__param2: result.applicationsession?.param2,
            applicationsession__param3: result.applicationsession?.param3,
            applicationsession__param4: result.applicationsession?.param4,
            applicationsession__phone: result.applicationsession?.phone,
            applicationsession__postalcode:
              result.applicationsession?.postalcode,
            created: result.created,
            comments: (result.contactchat || { notes: [] }).notes.map(
              (note) => {
                return {
                  id: note.id,
                  created: note.created,
                  name: `${note?.user?.first_name || ''} ${
                    note?.user?.last_name || ''
                  }`,
                  message: note.comment,
                  commentId: result.contactchat.id,
                }
              }
            ),
          }
        })

        return {
          count,
          results: response,
        }
      })
  }

  saveMessageComment(request) {
    const { customerId } = request
    return this.api
      .post(
        'service/messagecomment',
        {
          applicationmessageid: request.id,
          comment: request.comment,
        },
        true,
        {
          'X-Customer': customerId,
        }
      )
      .then(() => {
        return {
          success: true,
        }
      })
  }

  getMessageColumns(request) {
    const { columns, customerId } = request
    return this.api
      .get('service/messagecolumns', {}, true, {
        'X-Customer': customerId,
        'X-Filter': JSON.stringify(columns),
      })
      .then((response) => {
        return response
      })
  }

  getEventsColumns(request) {
    const { columns, customerId } = request
    return this.api
      .get('service/eventscolumns', {}, true, {
        'X-Customer': customerId,
        'X-Filter': columns.length
          ? JSON.stringify({ showcolumns: columns })
          : '',
      })
      .then((response) => {
        return response
      })
  }

  downloadMessages(request) {
    const { customerId, filter, message } = request
    const { download } = filter
    return this.api
      .get(
        'service/downloadmessages',
        {},
        true,
        {
          'X-Customer': customerId,
          'X-Filter': JSON.stringify(filter),
        },
        download === 'excel' ? true : false
      )
      .then((result) => {
        fileDownloader({
          result,
          filename: 'message',
          type: download,
          message: message,
        })
      })
  }
}
