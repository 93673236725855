export class GroupMessage {
  constructor(api) {
    this.api = api
    this.endpointUrl = 'app/sendmessage'
  }

  get(request) {
    const { id, customerId } = request
    return this.api
      .get(`${this.endpointUrl}/${id}`, {}, true, {
        'X-Customer': customerId,
      })
      .then((response) => {
        return response
      })
  }

  post(request) {
    const { customerId } = request
    let data = {
      applicationid: request.applicationId,
      applicationevent_key: request.applicationEventKey,
      eventid: 0,
      name: request.name,
      description: '',
      status: '',
      sendsmsaction: {
        enableduplicates: request.enableduplicates || undefined,
        recipients: 0,
        status: '',
        message: {
          source: request.source,
          senderlistid: request.senderlistid,
          groups: request.groups,
          msisdns: request.msisdns.filter(Boolean) || null,
          smsbody: request.smsbody,
        },
        schedule: request.schedule,
        remind: request.remindMessage,
      },
    }

    return this.api
      .post(`${this.endpointUrl}`, data, true, {
        'X-Customer': customerId,
      })
      .then(() => {
        return {
          success: true,
        }
      })
  }

  update(request) {
    const { id, customerId, action, data } = request
    return this.api
      .put(
        `${this.endpointUrl}/${id}`,
        {
          action,
          ...data,
        },
        true,
        {
          'X-Customer': customerId,
        }
      )
      .then((response) => {
        return response
      })
  }

  delete(request) {
    const { id, customerId } = request
    return this.api
      .delete(`${this.endpointUrl}/${id}`, {}, true, {
        'X-Customer': customerId,
      })
      .then(() => {
        return {
          success: true,
        }
      })
  }
}
