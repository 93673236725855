import { Redirect } from 'react-router-dom'
import {
  extend,
  isArray,
  isEqual,
  isFunction,
  isString,
  isUndefined,
  keys,
  pick,
  snakeCase,
  toUpper,
} from 'lodash'
import { createAction, handleActions } from 'redux-actions'
import { put } from 'redux-saga/effects'

import { setNotification } from '../common/notification/Notification'
import { t } from '../i18n'
import { history } from '../routes'
import { bind as bindMethods, getRecursive } from './utils'

/**
 * Prefix
 */
export const FORM_PREFIX = 'FORM_'
export const FORM_SUFFIX_COMPLETE = '_COMPLETE'
export const FORM_SUFFIX_FAIL = '_FAIL'
export const FORM_SUFFIX_SUCCESS = '_SUCCESS'

/**
 * Actions
 */
export const FORM_BUSY = 'FORM_BUSY'
export const formBusy = createAction(FORM_BUSY)

/**
 * Form initial state
 */
export const formState = {
  action: '',
  busy: false,
  error: '',
  message: '',
  redirect: '',
}

/**
 * Bind form
 */
export function bind(component, action, inputs = {}) {
  component.keys = keys(inputs)
  component.state = extend(component.state || {}, inputs)
  component.consume = (actions = action) => {
    const { form } = component.props
    if (!isArray(actions)) {
      actions = [actions]
    }
    for (let i = 0; i < actions.length; i++) {
      if (
        form.action === actions[i] &&
        form.busy === false &&
        !form.error &&
        form.redirect
      ) {
        return true
      }
    }
    return false
  }
  component.onChange = (e, value = '') => {
    const useEvent = !isString(e),
      name = useEvent ? e.target.name : e
    if (useEvent) {
      switch (e.target.type.toLowerCase()) {
        case 'checkbox':
          value = !!e.target.checked
          break
        default:
          value = e.target.value
          break
      }
    }
    if (name.indexOf('.') >= 0) {
      component.setState((state) => getRecursive(state, name.split('.'), value))
    } else {
      component.setState({
        [name]: value,
      })
    }
  }
  component.onSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (action) {
      component.submit(action, pick(component.state, component.keys))
    }
  }
  component.redirect = (target) => {
    if (isUndefined(target)) {
      target = (component.props.form || {}).redirect
    }
    if (isUndefined(target)) {
      target = '/'
    }
    return <Redirect to={target} />
  }
  component.submit = (submitAction, payload) => {
    return formSubmit(component.props.dispatch, submitAction, payload)
  }
  bindMethods(component, [
    'consume',
    'onChange',
    'onSubmit',
    'redirect',
    'submit',
  ])
  return component
}

/**
 * Form action
 */
export function formAction(action, suffix = '') {
  return FORM_PREFIX + toUpper(snakeCase(action)) + suffix
}

/**
 * Form complete
 */
export function* formComplete(action, payload = {}) {
  yield put({
    type: formAction(action, FORM_SUFFIX_COMPLETE),
    payload,
  })
  yield put({
    type: 'FORM_COMPLETE',
    payload: {},
  })
}

/**
 * Form dispatch to props
 */
export function formDispatchToProps(callback) {
  return (dispatch, props) => {
    return extend(isFunction(callback) ? callback(dispatch, props) : {}, {
      dispatch: dispatch,
    })
  }
}

/**
 * Form error message
 */
export function formErrorMessage(error) {
  return (error || {}).message || error || 'Unknown error'
}

/**
 * Form fail
 */
export function* formFail(action, error, payload = {}, redirect) {
  yield put({
    type: formAction(action, FORM_SUFFIX_FAIL),
    payload,
  })
  yield put({
    type: 'FORM_FAIL',
    payload: {
      action,
      error,
      redirect,
    },
  })
  yield formComplete(action)

  setNotification({
    variant: 'error',
    message: error,
  })
}

/**
 * Initialize form
 */
export function formInit(app) {
  return history.listen(() => {
    if (!isEqual(app.props.form, formState)) {
      app.props.dispatch(formReset())
    }
  })
}

/**
 * Reset form
 */
export function formReset(field) {
  return {
    type: 'FORM_RESET',
    payload: {
      field,
    },
  }
}

/**
 * Form state to props
 */
export function formStateToProps(callback) {
  return (state, props) => {
    return extend(isFunction(callback) ? callback(state, props) : {}, {
      form: state.form || formState,
    })
  }
}

/**
 * Form submit
 */
export function formSubmit(dispatch, action, payload = {}) {
  dispatch({
    type: 'FORM_SUBMIT',
    payload: {
      action,
    },
  })
  dispatch({
    type: formAction(action),
    payload,
  })
}

/**
 * Form success
 */
export function* formSuccess(action, payload = {}, message, redirect) {
  if (isFunction(redirect)) {
    redirect = redirect(payload)
  }
  yield put({
    type: formAction(action, FORM_SUFFIX_SUCCESS),
    payload,
  })
  yield put({
    type: 'FORM_SUCCESS',
    payload: {
      action,
      message,
      redirect,
    },
  })
  yield formComplete(action)

  if (message) {
    setNotification({
      variant: 'success',
      message: isFunction(message) ? t(message()) : t(message),
    })
  }
}

/**
 * Get parent form
 */
export function getParentForm(element) {
  if (!element) {
    return null
  }
  if (element.tagName.toLowerCase() === 'form') {
    return element
  }
  return getParentForm(element.parentNode)
}

/**
 * Reducer
 */
export const formReducer = handleActions(
  {
    FORM_BUSY: (state, { payload }) => {
      return extend({}, state, {
        busy: !!payload,
      })
    },
    FORM_COMPLETE: (state) => {
      return extend({}, state, {
        busy: false,
      })
    },
    FORM_FAIL: (state, { payload: { action, error, redirect } }) => {
      return extend({}, state, {
        action: action,
        error: error,
        message: '',
        redirect: redirect || '',
      })
    },
    FORM_RESET: (state, { payload: { field } }) => {
      if (field) {
        return extend({}, state, {
          [field]: formState[field],
        })
      } else {
        return formState
      }
    },
    FORM_SUCCESS: (state, { payload: { action, message, redirect } }) => {
      return extend({}, state, {
        action: action,
        error: '',
        message: message,
        redirect: redirect || '',
      })
    },
    FORM_SUBMIT: (state, { payload: { action } }) => {
      return extend({}, state, {
        action,
        busy: true,
        error: '',
        message: '',
        redirect: '',
      })
    },
  },
  formState
)
