import { isNull } from 'lodash'

export class Dashboard {
  constructor(api) {
    this.api = api
  }

  getIndex(request) {
    const { customerId } = request
    return this.api
      .get('service/index', {}, true, {
        'X-Customer': customerId,
      })
      .then((results) => {
        let statisticsData = {
          lineChart: {
            data: [],
            total: 0,
          },
          pieChart: {
            data: [],
            total: 0,
            color: [],
          },
        }

        if (results.statistics) {
          const {
            statistics: { statistics_line, statistics_pie },
          } = results
          let data = {
            line: {},
            pie: {},
          }

          ;(statistics_line.data || []).forEach((item, index) => {
            data.line[item.valuedate] = item.value
          })
          ;(statistics_pie.data || []).forEach((item, index) => {
            data.pie[item.label] = item.value
          })

          statisticsData = {
            lineChart: {
              data: data.line,
              total: statistics_line.total,
            },
            pieChart: {
              data: data.pie,
              total: statistics_pie.total,
              color: statistics_pie.colors,
            },
          }
        }
        const availApplications = !isNull(results.avail_applications)
          ? results.avail_applications
          : []
        return {
          ...statisticsData,
          applications: availApplications.map((app) => {
            return {
              id: app.id,
              tags: [app.channels],
              description: app.short_description,
              title: app.name,
              name: app.modulename,
              icon: app.icon,
            }
          }),
        }
      })
  }

  sendFeedback({ customerId, feedback }) {
    return this.api.post(
      'service/feedback',
      {
        feedback,
      },
      true,
      {
        'X-Customer': customerId,
      }
    )
  }
}
