export const applicationViewSelector = ({
  auth,
  configurations,
  messages: { applications },
}) => ({
  auth,
  configurations,
  applications: applications?.data?.all || [],
})

export const applicationsSelector = ({ messages: { applications } }) =>
  applications?.data?.all

export const templateSelector = ({ messages: { templates } }) => templates

export const userSelector = ({ auth: { user } }) => user

export const customerIdSelector = ({
  auth: {
    customer: {
      active: { id },
    },
  },
}) => id

export const dataCleanupObjectsSelector = ({
  configurations: { dataCleanupObjects },
}) => dataCleanupObjects

export const dataCleanupObjectsStateSelector = ({
  configurations: { dataCleanupObjectsState },
}) => dataCleanupObjectsState

export const dataCleanupOptionsSelector = ({
  configurations: { dataCleanupOptions },
}) => dataCleanupOptions

export const productGroupsSelector = ({
  system: {
    billing: { groups },
  },
}) => groups
