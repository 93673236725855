import { connect, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { isFunction, isUndefined, union } from 'lodash'

import { userSelector } from 'app/store/selectors'
import Auth from './Auth'

const ACCESS_LEVEL_MASKS = {
  view: [1, 3, 5, 7, 9, 11, 13, 15],
  add: [2, 3, 6, 7, 10, 11, 14, 15],
  change: [4, 5, 6, 7, 12, 13, 14, 15],
  delete: [8, 9, 10, 11, 12, 13, 14, 15],
}

export function applyGuards(item, index = 0) {
  const guard = (item.guards || [])[index]
  if (isUndefined(guard)) {
    return (
      <item.component {...item.props} {...item.extra} routes={item.routes} />
    )
  } else {
    const Guard = Auth
    return <Guard {...guard}>{applyGuards(item, index + 1)}</Guard>
  }
}

export function hasPolicyPermission(access, value, cb) {
  let levels = []
  access.forEach((element) => {
    levels = [...levels, ...ACCESS_LEVEL_MASKS[element]]
  })
  if (isFunction(cb)) {
    return cb(union(levels).includes(parseInt(value)))
  } else {
    return union(levels).includes(parseInt(value)) ? true : false
  }
}

export function PolicyGuards(props) {
  const { access, policyKey, policies, loggedIn } = props
  if (!loggedIn) {
    return <Redirect to="/login" />
  }
  return (
    <>
      {hasPolicyPermission(access, policies[policyKey] || 0) && props.children}
    </>
  )
}

export function RoleGuards({ children, roles }) {
  const user = useSelector(userSelector)
  const { role } = user
  if (!roles || roles.includes(role)) {
    return <>{children}</>
  }
  return null
}

export default connect(
  ({
    auth: {
      user: { policies },
      loggedIn,
    },
  }) => ({
    policies,
    loggedIn,
  })
)(PolicyGuards)
