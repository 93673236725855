const ThemeDefault = {
  primary: '#00CE37',
  primary2: '#00A52C',
  primary3: '#007C21',

  secondary: '#FF9800',
  secondary2: '#F57C00',
  secondary3: '#CF4900',

  textPrimary: '#001505',
  textPrimary2: '#3D3E3D',
  textPrimary3: '#666867',
  textPrimary4: '#CCD0CD',
  textPrimary5: '#F2F3F3',
  textPrimary6: '#F2F3F3',
}

export default ThemeDefault
