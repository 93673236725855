import { keys, random } from 'lodash'
import moment from 'moment'

import { bind } from '../common/utils'
import { fileDownloader } from '../common/utils'
import { getPageNumber } from './'

const color = {
  blue: '#00A0ED',
  yellow: '#FFC30E',
  orange: '#FF9800',
  darkOrange: '#F57C00',
  red: '#D13729',
  purple: '#800080',
  green: '#00CE37',
  darkGreen: '#00A52C',
  gray: '#CCD0CD',
}

/**
 * Distribute numbers
 */
export function distribute(amount, names = [], factor = 1) {
  const distributed = {},
    last = names.pop()
  let total = 0
  names.forEach((name) => {
    distributed[name] = random(total, amount * factor) - total
    total += distributed[name]
  })
  distributed[last] = amount - total
  return distributed
}

/**
 * Charsets
 */
export const logCharsets = {
  GSM: 'GSM',
  UCS: 'Unicode',
  BIN: 'Binary',
}

/**
 * Directions
 */
export const logDirections = {
  MO: { value: 'Received', color: color.darkGreen },
  MT: { value: 'Sent', color: color.blue },
}

/**
 * Log reasons
 */
export const logReasons = {
  BUFFERED: { value: 'Buffered', color: color.orange },
  EXPIRED: { value: 'Expired', color: color.red },
  CANCELED: { value: 'Canceled', color: color.yellow },
  FILTERED: { value: 'Filtered', color: color.orange },
  INVALID: { value: 'Invalid', color: color.red },
  TERMINAL_ERROR: { value: 'Terminal error', color: color.orange },
  CARRIER_ERROR: { value: 'Carrier error', color: color.orange },
  BILLING_ERROR: { value: 'Billing error', color: color.orange },
  SEND_ERROR: { value: 'Send error', color: color.darkOrange },
  LIMIT_EXCEEDED: { value: 'Limit exceeded', color: color.orange },
  APP_ERROR: { value: 'Application error', color: color.orange },
  RESTRICTED: { value: 'Restricted', color: color.red },
  SCHEDULED: { value: 'Scheluded', color: color.orange },
  ABSENT_SUBSCRIBER: { value: 'Absent subscriber', color: color.orange },
  TEMPLATE_ERROR: { value: 'Message template error', color: color.orange },
  UNKNOWN_NUMBER: { value: 'Unknown number', color: color.orange },
  UNKNOWN_ERROR: { value: 'Unknown error', color: color.orange },
  SUBSCRIBER_ERROR: { value: 'Subscriber error', color: color.orange },
}

/**
 * Log statuses
 */
export const logStatuses = {
  CREATED: { value: 'Created', color: color.blue },
  SENT: { value: 'Sent', color: color.yellow },
  ACKED: { value: 'Acked', color: color.purple },
  FAILED: { value: 'Failed', color: color.red },
  DELIVERED: { value: 'Delivered', color: color.green },
  UNKNOWN: { value: 'Unknown', color: color.gray },
  RETRY: { value: 'Retry', color: color.red },
}

/**
 * Log source types
 */
export const logSourceTypes = {
  ALNUM: 'Alphanumeric',
  NATIONAL: 'National',
  MSISDN: 'MSISDN',
}

/**
 * Tracking API
 */
export class Tracking {
  constructor(api) {
    this.api = api
    bind(this, [
      'getMessageLogs',
      'getMessageLogColumns',
      'getGraphicalReports',
      'getNumericalReports',
      'getSystemMessageLogs',
      'getSystemMessageLogColumns',
    ])
  }

  getMessageLogColumns(request) {
    const { columns, customerId } = request
    return this.api
      .get('service/messagelogfilters', {}, true, {
        'X-Customer': customerId,
        'X-Filter': columns.length
          ? JSON.stringify({ accountcolumns: columns })
          : '',
      })
      .then((response) => {
        return response
      })
  }

  getSystemMessageLogColumns(request) {
    const { columns, customerId } = request
    return this.api
      .get('mgmt/messagelogfilters', {}, true, {
        'X-Customer': customerId,
        'X-Filter': columns.length
          ? JSON.stringify({ accountcolumns: columns })
          : '',
      })
      .then((response) => {
        return response
      })
  }

  /**
   * Get settings
   */
  getMessageLogs(options = {}) {
    return this.api
      .get(`service/messagelogs?page=${getPageNumber(options)}`, {}, true, {
        'X-Customer': options.extra.customerId,
        ...this.api.withFilters(options, {
          sender: 'sender',
          destination: 'destination',
          body: 'body',
          created_range: 'created_range',
          statustime: 'statustime_range', //TODO: Check if this can be handled in the table component.
          status: 'status',
          bodyparts: 'bodyparts',
          bodytype: 'bodytype',
          reason: 'reason',
          sendertype: 'sendertype',
          direction: 'direction',
          batchid: 'batchid',
          billingref: 'billingref',
          countrycode: 'countrycode',
          customername: 'acustomerid',
          acustomerid: 'customername',
          drid: 'drid',
        }),
      })
      .then(({ count, results }) => {
        return {
          count,
          results,
        }
      })
  }

  getSystemMessageLogs(options = {}) {
    return this.api
      .get(`mgmt/messagelogs?page=${getPageNumber(options)}`, {}, true, {
        ...this.api.withFilters(options, {
          sender: 'sender',
          destination: 'destination',
          body: 'body',
          created_range: 'created_range',
          statustime: 'statustime_range', //TODO: Check if this can be handled in the table component.
          statustext: 'statustext',
          status: 'status',
          bodyparts: 'bodyparts',
          bodytype: 'bodytype',
          reason: 'reason',
          sendertype: 'sendertype',
          direction: 'direction',
          batchid: 'batchid',
          billingref: 'billingref',
          countrycode: 'countrycode',
          customername: 'acustomerid',
          acustomerid: 'customername',
          drid: 'drid',
        }),
      })
      .then(({ count, results }) => {
        return {
          count,
          results,
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  downloadMessageLogs(customerId, filter, message) {
    const { created_range, download } = filter
    return this.api
      .get(
        'service/downloadmessagelog',
        {},
        true,
        {
          'X-Customer': customerId,
          'X-filter': JSON.stringify(filter),
        },
        download === 'excel' ? true : false
      )
      .then((result) => {
        let filename = `${moment().format('DDMMYYYY')}_${moment().format(
          'DDMMYYYY'
        )}`
        if (created_range) {
          const range = created_range.split(' - ')
          filename = `${range[0].replace(/[.]+/g, '')}_ ${range[1].replace(
            /[.]+/g,
            ''
          )}`
        }
        fileDownloader({
          result,
          filename: `log_${filename}`,
          type: download,
          message: message,
        })
      })
  }

  downloadSystemMessageLogs(customerId, filter, message) {
    const { created_range, download } = filter
    return this.api
      .get(
        'mgmt/downloadmessagelog',
        {},
        true,
        {
          'X-filter': JSON.stringify(filter),
        },
        download === 'excel' ? true : false
      )
      .then((result) => {
        let filename = `${moment().format('DDMMYYYY')}_${moment().format(
          'DDMMYYYY'
        )}`
        if (created_range) {
          const range = created_range.split(' - ')
          filename = `${range[0].replace(/[.]+/g, '')}_ ${range[1].replace(
            /[.]+/g,
            ''
          )}`
        }
        fileDownloader({
          result,
          filename: `log_${filename}`,
          type: download,
          message: message,
        })
      })
  }

  getGraphicalReports(request) {
    const { customerId, customer, dateRange, group, resolution, type } = request

    return this.api
      .get('service/statistics', {}, true, {
        'X-Customer': customerId,
        'X-filter': JSON.stringify({
          daterange: dateRange,
          customerid: customer,
          group,
          resolution,
          type,
        }),
      })
      .then((results) => {
        let statisticsData = {
          lineChart: {
            labels: [],
            series: [],
            total: 0,
          },
          pieChartStatus: {
            data: [],
            total: 0,
            color: [],
          },
          pieChartReason: {
            data: [],
            total: 0,
            color: [],
          },
        }

        const data = {
          line: {},
          pieStatus: {},
          pieReason: {},
        }

        let labels = []
        let series = []

        const { linechart, status_piechart, reason_piechart } = results

        switch (group) {
          case 'direction':
            let mtData = [],
              moData = []

            ;(linechart.data || []).forEach((item, index) => {
              labels.push(item.reportdate)
              moData.push(item.value_MO)
              mtData.push(item.value_MT)
            })

            series.push({
              name: 'MT',
              type: 'line',
              data: mtData,
              itemStyle: {
                color: logDirections['MT'].color,
              },
              lineStyle: {
                color: logDirections['MT'].color,
              },
            })

            series.push({
              name: 'MO',
              type: 'line',
              data: moData,
              itemStyle: {
                color: logDirections['MO'].color,
              },
              lineStyle: {
                color: logDirections['MO'].color,
              },
            })

            break
          case 'status':
            const statusData = {
              CREATED: [],
              SENT: [],
              ACKED: [],
              FAILED: [],
              DELIVERED: [],
              UNKNOWN: [],
              RETRY: [],
            }

            ;(linechart.data || {}).map((item) => {
              labels.push(item.reportdate)

              Object.keys(statusData).forEach((status) => {
                statusData[status].push(item[`value_${status}`])
              })

              return false
            })

            Object.keys(statusData).forEach((status) => {
              series.push({
                name: logStatuses[status].value,
                type: 'line',
                data: statusData[status],
                itemStyle: {
                  color: logStatuses[status].color,
                },
                lineStyle: {
                  color: logStatuses[status].color,
                },
              })
            })
            break
          case 'none':
          default:
            let nonGroupData = []

            ;(linechart.data || []).forEach((item, index) => {
              labels.push(item.valuedate)
              nonGroupData.push(item.value)
            })

            series.push({
              name: 'Messages',
              type: 'line',
              data: nonGroupData,
              itemStyle: {
                color: color.darkGreen,
              },
              lineStyle: {
                color: color.darkGreen,
              },
            })
            break
        }

        ;(status_piechart.data || []).forEach((item, index) => {
          data.pieStatus[item.label] = item.value
        })
        ;(reason_piechart.data || []).forEach((item, index) => {
          data.pieReason[item.label] = item.value
        })

        statisticsData = {
          lineChart: {
            labels,
            series,
            total: linechart.total,
          },
          pieChartStatus: {
            data: data.pieStatus,
            total: status_piechart.total,
            color: status_piechart.colors,
          },
          pieChartReason: {
            data: data.pieReason,
            total: reason_piechart.total,
            color: reason_piechart.colors,
          },
        }

        return statisticsData
      })
  }

  getNumericalReports = (request = {}) => {
    const {
      extra: {
        customerId,
        download,
        message,
        filterInputs: { customer, billable, date, area, division, groupings },
      },
    } = request

    const groupingsFilter = {
      group_direction: 'direction',
      group_customer: 'customer__name',
      group_billingref: 'billingref',
      group_countrycode: 'countrycode',
      group_operator: 'operator',
      group_status: 'status',
    }

    let groups = {}
    keys(groupingsFilter).forEach((value) => {
      if (groupings.includes(value)) {
        groups = {
          ...groups,
          [value]: true,
        }
      }
    })

    const options = {
      ...request,
      search: {
        ...request.search,
        customerid: customer,
        messages_type: billable,
        daterange: `${date.from} - ${date.to}`,
        area,
        division,
        download,
        ...groups,
      },
      sort: groupingsFilter[request.sort]
        ? [groupingsFilter[request.sort]]
        : request.sort,
    }

    const path = download !== '' ? 'downloadreports' : 'reports'

    return this.api
      .get(
        `service/${path}?page=${getPageNumber(options)}`,
        {},
        true,
        {
          'X-Customer': customerId,
          ...this.api.withFilters(options, {
            customerid: 'customerid',
            messages_type: 'messages_type',
            area: 'area',
            daterange: 'daterange',
            division: 'resolution',
            download: 'download',
            group_customer: 'group_customer',
            group_direction: 'group_direction',
            group_billingref: 'group_billingref',
            group_countrycode: 'group_countrycode',
            group_operator: 'group_operator',
            group_status: 'group_status',
            reportdate: 'reportdate',
            messages: 'messages',
            direction: 'direction',
            billingref: 'billingref',
            countrycode: 'countrycode',
            operator: 'operator',
            customer__name: 'customer__name',
            status: 'status',
          }),
        },
        download === 'excel' ? true : false
      )
      .then((result) => {
        if (download !== '') {
          fileDownloader({
            result,
            filename: 'report',
            type: download,
            message: message,
          })
          return this.getNumericalReports({
            ...request,
            extra: { ...request.extra, download: '', message: '' },
          })
        } else {
          const { results, count } = result
          return {
            count,
            results: results.map(
              ({
                reportdate,
                messages,
                customer__name,
                direction,
                status,
                billingref,
                countrycode,
                operator,
                area,
              }) => {
                return {
                  reportdate,
                  messages,
                  area,
                  group_customer: customer__name || '',
                  group_direction: direction || '',
                  group_billingref: billingref || '',
                  group_countrycode: countrycode || '',
                  group_operator: operator || '',
                  group_status: status || '',
                }
              }
            ),
          }
        }
      })
  }
}
