import { bind } from '../common/utils'

export class Configurations {
  constructor(api) {
    this.api = api
    bind(this, ['getConfigurations'])
  }

  getConfigurations(request) {
    return this.api
      .get(`service/configurations`, {}, true, {})
      .then((result) => {
        return {
          customStylingEnabled: result.custom_styling_enabled,
          customStylingLocation: result.custom_styling_location,
          messageRouterName: result.message_router_name,
          messageSuiteName: result.message_suite_name,
          notificationEmailSenders: result.notification_email_senders,
          drEmailSenders: result.dr_email_senders,
          passwordinfo: result.passwordinfo,
          backendVersion: result.backend_version,
        }
      })
  }
}
