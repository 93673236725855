import moment from 'moment'

import { fileDownloader } from '../../common/utils'

export class MoDialog {
  constructor(api) {
    this.api = api
    this.endpointUrl = 'app/modialog'
  }

  get(request) {
    const { id, customerId } = request
    return this.api
      .get(`${this.endpointUrl}/${id}`, {}, true, {
        'X-Customer': customerId,
      })
      .then((response) => {
        const { dialog_data, dialogsmsaction } = response
        return {
          ...response,
          dialog: {
            id: dialog_data[0].id,
            parentMessage: dialog_data[0].response,
            errorMessage: dialog_data[0].errormessage,
            dialogsmsaction,
            ...this.recursiveTreeMessage(dialog_data[0].children),
          },
        }
      })
  }

  post(request) {
    const { customerId } = request
    let data = {
      applicationid: request.applicationId,
      name: request.name,
      description: '',
      status: '',
      eventid: 0,
      dialogsmsaction: {
        destination: request.number,
        keyword: '',
        status: '',
        dialog_enddatetime: moment(
          `${request.dialogEndDate} ${request.dialogEndTime}`,
          'DD.MM.YYYY HH:mm'
        ).format('YYYY-MM-DD HH:mm:ss'),
      },
      dialog_data: request.dialog_data,
    }

    return this.api
      .post(`${this.endpointUrl}`, data, true, {
        'X-Customer': customerId,
      })
      .then((response) => {
        return {
          id: response.id,
          applicationId: response.applicationid,
          sender: response.sender,
        }
      })
  }

  update(request) {
    const { id, customerId, action } = request
    return this.api
      .put(
        `${this.endpointUrl}/${id}`,
        {
          action,
        },
        true,
        {
          'X-Customer': customerId,
        }
      )
      .then((response) => {
        return response
      })
  }

  delete(request) {
    const { id, customerId } = request
    return this.api
      .delete(`${this.endpointUrl}/${id}`, {}, true, {
        'X-Customer': customerId,
      })
      .then(() => {
        return {
          success: true,
        }
      })
  }

  getReports = (request = {}) => {
    const {
      extra: {
        customerId,
        id,
        columns,
        download,
        filterInput: { reportDaterange, number, activeDialogBox, onLoad },
      },
    } = request

    const options = {
      ...request,
      search: {
        ...request.search,
        showcolumns: columns || '',
        reportDaterange: !onLoad
          ? `${reportDaterange.from} - ${reportDaterange.to}`
          : {},
        number,
        download,
        activeDialogBox,
      },
    }

    return this.api
      .get(
        `${this.endpointUrl}report/${id}`,
        {},
        true,
        {
          'X-Customer': customerId,
          ...this.api.withFilters(options, {
            reportDaterange: 'report_daterange',
            number: 'msisdn',
            contact__msisdn: 'contact__msisdn',
            itemmessage: 'itemmessage',
            response: 'response',
            created: 'created',
            showcolumns: 'showcolumns',
            download: 'download',
            activeDialogBox: 'activedialogbox',
          }),
        },
        download === 'excel' ? 'true' : false
      )
      .then((result) => {
        const {
          responses,
          count,
          report_daterange,
          dialog_data,
          showcolumns,
          guicolumns,
          activedialogbox,
          rawreportcolumns,
        } = result

        if (download !== '') {
          fileDownloader({
            result,
            filename: `dialogreport`,
            type: download,
          })
          return this.getReports({
            ...request,
            extra: { ...request.extra, download: '' },
          })
        }

        return {
          count,
          results: responses,
          additional: {
            rawreportcolumns,
            report_daterange,
            showcolumns,
            guicolumns,
            activedialogbox,
            dialog: {
              id: dialog_data[0].id,
              parentMessage: dialog_data[0].response,
              ...this.recursiveTreeMessage(dialog_data[0].children),
            },
          },
        }
      })
  }

  getStatistics(request) {
    const { id, customerId, dateRange } = request
    return this.api
      .get(`${this.endpointUrl}statistics/${id}`, {}, true, {
        'X-Customer': customerId,
        'X-filter': JSON.stringify({
          statistics_daterange: dateRange,
        }),
      })
      .then(
        ({
          COMPLETED_DIALOGS_PIE_CHART,
          SENT_STATUS_PIE_CHART,
          STARTED_DIALOGS_PIE_CHART,
          completed_dialogs,
          sent_dialog_messages,
          started_dialogs,
          statistics_daterange,
          dialog_statistic,
        }) => {
          let data = {
            pieSent: {},
            pieStarted: {},
            pieCompleted: {},
          }

          ;(SENT_STATUS_PIE_CHART.data || []).forEach((item, index) => {
            data.pieSent[item.label] = item.value
          })
          ;(STARTED_DIALOGS_PIE_CHART.data || []).forEach((item, index) => {
            data.pieStarted[item.label] = item.value
          })
          ;(COMPLETED_DIALOGS_PIE_CHART.data || []).forEach((item, index) => {
            data.pieCompleted[item.label] = item.value
          })

          return {
            pieChartSent: {
              data: data.pieSent,
              total: SENT_STATUS_PIE_CHART.total,
              color: SENT_STATUS_PIE_CHART.colors,
            },
            pieChartStarted: {
              data: data.pieStarted,
              total: STARTED_DIALOGS_PIE_CHART.total,
              color: STARTED_DIALOGS_PIE_CHART.colors,
            },
            pieChartCompleted: {
              data: data.pieCompleted,
              total: COMPLETED_DIALOGS_PIE_CHART.total,
              color: COMPLETED_DIALOGS_PIE_CHART.colors,
            },
            completed_dialogs,
            sent_dialog_messages,
            started_dialogs,
            statistics_daterange,
            dialog: {
              parentMessage: dialog_statistic.question,
              ...this.recursiveTreeMessage(dialog_statistic.children, true),
            },
          }
        }
      )
  }

  recursiveTreeMessage(data, isStatistics = false) {
    if (!data.length) return {}
    return {
      messages: data.map((result) => {
        const {
          id,
          choices,
          response,
          question,
          children,
          errormessage,
          item_answers,
          parent_answers,
          sms_notification,
          email_notification,
        } = result

        let statistics = {}
        if (isStatistics) {
          const calcPct = ((item_answers / parent_answers) * 100).toFixed(2)
          statistics = {
            answers: item_answers || 0,
            percentage: isNaN(calcPct) ? 0.0 : calcPct,
          }
        }

        return {
          id,
          request: choices,
          response: question ? question : response,
          dialog: {
            errorMessage: errormessage || null,
            ...this.recursiveTreeMessage(children, isStatistics),
          },
          statistics,
          sms: sms_notification,
          email: email_notification,
        }
      }),
    }
  }

  downloadReportsRaw(request) {
    const { id, number, reportDaterange, onLoad, download, customerId } =
      request

    const columns = request.columns.join(',')

    const options = {
      ...request,
      search: {
        reportDaterange: !onLoad
          ? `${reportDaterange.from} - ${reportDaterange.to}`
          : {},
        number,
        download,
        columns,
      },
    }

    return this.api
      .get(
        `${this.endpointUrl}reportraw/${id}`,
        {},
        true,
        {
          'X-Customer': customerId,
          ...this.api.withFilters(options, {
            reportDaterange: 'report_daterange',
            number: 'msisdn',
            download: 'download',
            columns: 'optional',
          }),
        },
        download === 'excel' ? 'true' : false
      )
      .then((result) => {
        fileDownloader({
          result,
          filename: `dialogreportraw`,
          type: download,
        })
      })
  }
}
