// @ts-nocheck
// This is a tsx file so we get to define argument and return types for functions,
// but we're not rewriting the file completely into TS at this time.
// This is why TS checking is disabled for the file.
import axios from 'axios'
import * as faker from 'faker'
import { keys, random, range, sample, upperFirst } from 'lodash'

import { BillingType } from 'app/types/app'
import { bind, mapKeyValues } from '../common/utils'
import { ProductIn } from './serializers/Product'
import { ProductIO } from './serializers'
import { getPageNumber, getTableQueryHeaders } from '.'

export const billingTypes = {
  once: 'Once',
  recurring: 'Recurring',
  transaction: 'Transaction',
}

export const statuses = {
  created: 'Created',
  active: 'Active',
  ended: 'Ended',
}

export const transactionTypes = {
  SMS: 'SMS',
}

/**
 * Billing API
 */
export class Billing {
  constructor(api) {
    this.api = api
    bind(this, [
      'deleteGroup',
      'deleteProduct',
      'getAllGroups',
      'getAllProducts',
      'getGroup',
      'getGroups',
      'getProduct',
      'getProducts',
      'saveGroup',
      'saveProduct',
      'addPrice',
      'deletePrice',
      'getBillingItems',
      'getBillingStatistics',
      'getInvoices',
      'getInvoiceData',
      'getInvoiceItems',
    ])
  }

  /**
   * Delete group
   */
  deleteGroup({ id }) {
    return this.api.delete(`mgmt/productgroups/${id}`).then(() => {
      return {
        success: true,
      }
    })
  }

  /**
   * Delete product
   */
  deleteProduct({ id }) {
    return this.api.delete(`mgmt/products/${id}`).then(() => {
      return {
        success: true,
      }
    })
  }

  /**
   * Get all groups
   */
  async getAllGroups() {
    const { results } = await this.getGroups()
    return mapKeyValues(
      results.map(({ id, name }) => ({ id, name })),
      'id',
      'name'
    )
  }

  async getAllProducts() {
    const { results } = await this.getProducts()
    return results
  }

  /**
   * Get group
   */
  getGroup({ id }) {
    return this.api.get(`mgmt/productgroups/${id}`).then((result) => result)
  }

  /**
   * Get product
   */
  async getProduct({ id }) {
    const result = (await this.api.get(`mgmt/products/${id}`)) as ProductIn
    return ProductIO.in(result)
  }

  /**
   * Get groups
   */
  getGroups(options = {}) {
    return this.api
      .get(
        'mgmt/productgroups?page=' + getPageNumber(options),
        {},
        true,
        this.api.withFilters(options, {
          name: 'name',
          description: 'description',
          created: 'created',
        })
      )
      .then(({ count, results }) => {
        return {
          count,
          results,
        }
      })
  }

  /**
   * Get products
   */
  async getProducts(options = {}) {
    return this.api
      .get(
        'mgmt/products?page=' + getPageNumber(options),
        {},
        true,
        this.api.withFilters(options, {
          name: 'name',
          status: 'status',
          productgroup: 'productgroup__name',
          billingtype: 'billingtype',
          code: 'code',
          price: 'pricinginfo__price',
          price_vat: 'pricinginfo__price_vat',
          purchase_price: 'pricinginfo__purchase_price',
          purchase_vat: 'pricinginfo__purchase_vat',
          description: 'description',
          created: 'created',
          operator: 'smstransactionbilling__operator',
          direction: 'smstransactionbilling__direction',
          countrycode: 'smstransactionbilling__countrycode',
          transaction_type: 'transaction_type',
        })
      )
      .then(({ count, results }) => {
        return {
          count,
          results: results.map(ProductIO.in),
        }
      })
  }

  /**
   * Get mock groups
   */
  getMockGroups() {
    if (!this.groups) {
      this.groups = [
        ...range(10).map((i) => {
          return {
            id: i + 1 + '',
            name: upperFirst(faker.lorem.words(random(2, 3))),
            description: faker.lorem.sentence(),
            created: faker.date.recent(90),
          }
        }),
      ]
      this.lastGroupId = this.groups.length
    }
    return this.groups
  }

  /**
   * Get mock products
   */
  getMockProducts() {
    if (!this.products) {
      this.products = [
        ...range(100).map((i) => {
          return {
            id: i + 1 + '',
            name: upperFirst(faker.lorem.words(random(2, 3))),
            group: sample(this.getMockGroups()),
            billingtype: sample(keys(BillingType)),
            code: faker.random.alphaNumeric(random(6, 12)),
            price: faker.commerce.price(50, 500),
            price_vat: faker.commerce.price(10, 50),
            description: faker.lorem.sentence(),
            created: faker.date.recent(90),
          }
        }),
      ]
      this.lastProductId = this.products.length
    }
    return this.products
  }

  /**
   * Save group
   */
  saveGroup({ id, name, description }) {
    const data = {
      name,
      description: description || null,
    }
    if (!id) {
      return this.api.post('mgmt/productgroups', data).then((result) => ({
        ...result,
        new: true,
      }))
    } else {
      return this.api
        .put(`mgmt/productgroups/${id}`, data)
        .then((result) => result)
    }
  }

  /**
   * Save product
   */
  async saveProduct({
    id,
    name,
    code,
    billingtype,
    description,
    productgroup,
    transaction_type,
    sms_transaction_billing,
  }) {
    const data = {
      name,
      description,
      code,
      productgroup,
      billingtype,
      transaction_type,
    }

    if (billingtype === BillingType.TRANSACTION) {
      if (transaction_type === 'SMS') {
        data.sms_transaction_billing = sms_transaction_billing
      }
    } else {
      delete data.transaction_type
    }

    let result: ProductIn
    if (id) {
      result = await this.api.put(`mgmt/products/${id}`, data)
    } else {
      result = await this.api.post('mgmt/products', data)
    }
    return ProductIO.in(result)
  }

  addCost(productId, data) {
    return this.api.post(`mgmt/products/${productId}/purchase`, data)
  }

  addPrice(productId, data) {
    return this.api.post(`mgmt/products/${productId}/price`, data)
  }

  addCustomPrice(productId, data) {
    return this.api.post(`mgmt/products/${productId}/customprice`, data)
  }

  async deletePrice(priceId) {
    try {
      return await this.api.delete(`mgmt/prices/${priceId}`)
    } catch (error) {
      if (!axios.isAxiosError(error) || error.response?.status !== 404) {
        throw error
      }
    }
  }

  getBillingItems(options) {
    return this.api.get(
      `mgmt/billingdata?page=${getPageNumber(options)}`,
      {},
      true,
      getTableQueryHeaders(options)
    )
  }

  getBillingStatistics(search) {
    const options = {
      filters: {
        search,
      },
    }
    return this.api.get(
      `mgmt/billingstatistics`,
      {},
      true,
      getTableQueryHeaders(options)
    )
  }

  getInvoices(options) {
    return this.api.get(
      `mgmt/invoicing?page=${getPageNumber(options)}`,
      {},
      true,
      getTableQueryHeaders(options)
    )
  }

  getInvoiceData(invoiceId) {
    return this.api.get(`mgmt/invoicing/${invoiceId}`).then((result) => result)
  }

  getInvoiceItems(options) {
    return this.api
      .get(
        `mgmt/invoicing/data?page=${getPageNumber(options)}`,
        {},
        true,
        getTableQueryHeaders(options)
      )
      .then((result) => result)
  }
}
