import { Component } from 'react'
import { connect } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import CacheBuster from '../cache/CacheBuster'
import { actionCall } from './common/action'
import { formInit } from './common/form'
import Loading from './common/loading/Loading'
import Notification from './common/notification/Notification'
import { CustomTitle } from './common/title/Title'
import { bind, getContactEmail, isOldIe } from './common/utils'
import { appClick } from './actions'
import { wrap } from './i18n'
import AppRoutes from './routes'
import { initialize } from './sagas'
import { ThemeDefault } from './themes'

import fi from './locale.fi.json'
import './App.scss'

class App extends Component {
  constructor(props) {
    super(props)
    bind(this, ['onClick'])

    this.state = {
      isNotSupported: false,
    }
  }

  render() {
    const { t, busy, ready } = this.props
    const title = process.env.REACT_APP_TITLE
    const contactEmail = getContactEmail()

    return (
      <ThemeProvider theme={ThemeDefault}>
        <CacheBuster>
          {({ loading, isLatestBuildDate, refreshCacheAndReload }) => {
            if (loading) return null
            if (!loading && !isLatestBuildDate) {
              refreshCacheAndReload()
            }
            return this.state.isNotSupported ? (
              <div
                style={{ height: 'inherit' }}
                className="d-flex justify-content-center align-items-center"
              >
                <div className="col-sm-8 col-md-6 p-3">
                  <h3 className="mb-3">{t('Please upgrade your browser')}</h3>
                  <p className="mb-3">
                    {t(
                      'Support for Internet Explorer has come to an end. IE11 will meet its end of life in June 2022 meaning any vulnerabilities that are discovered after that will not be fixed.'
                    )}
                  </p>
                  <p>
                    {t(
                      'We no longer support Internet Explorer and we kindly ask you to switch to a modern browser (e.g. Google Chrome or Mozilla Firefox).'
                    )}{' '}
                    {t('If you have any questions please contacts us at')}{' '}
                    <a
                      href={'mailto:' + contactEmail}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {contactEmail}
                    </a>
                    .
                  </p>
                  <p>{t('Thank you!')}</p>
                </div>
              </div>
            ) : (
              <div
                className={'App ' + (busy ? 'busy' : 'active')}
                onClick={this.onClick}
              >
                {!ready ? <Loading /> : <AppRoutes />}
                <Notification />
                <CustomTitle title={title} />
              </div>
            )
          }}
        </CacheBuster>
      </ThemeProvider>
    )
  }

  componentDidMount() {
    if (isOldIe()) {
      this.setState({ isNotSupported: true })
    } else {
      actionCall(this.props.dispatch, initialize)
      this.unlisten = formInit(this)
    }
  }

  componentWillUnmount() {
    this.unlisten()
  }

  onClick(e) {
    this.props.dispatch(appClick(e))
  }

  get isCustomStyled() {
    return process.env.REACT_APP_CUSTOM_ENABLED === 'true'
  }
}

export default connect(({ app: { busy, ready }, form }) => {
  return {
    busy,
    form,
    ready,
  }
})(wrap(App, { fi }))
