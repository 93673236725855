import React, { Component } from 'react'
import Form from 'react-bootstrap/Form'
import BootstrapTable from 'react-bootstrap/Table'
import { connect } from 'react-redux'
import clsx from 'clsx'
import {
  extend,
  forEach,
  isEmpty,
  isFunction,
  isUndefined,
  pick,
  pickBy,
  range,
} from 'lodash'
import moment from 'moment'

import { t } from '../../i18n'
import { actionCall, watch } from '../action'
import CountryCodeSelect from '../country-code-select/CountryCodeSelect'
import DatePickerWrapper from '../date-picker/DatePickerWrapper'
import Icon from '../Icon'
import Loading from '../loading/Loading'
import Pagination from '../pagination/Pagination'
import { bind, keys } from '../utils'
import VatSelect from '../vat-select/VatSelect'
import { tableState } from './reducer'

import './Table.scss'

export const tableBusy = 'table-busy'
export const tableReset = 'table-reset'
export const tableQuery = 'table-query'
export const ORDERS = ['asc', 'desc']

/**
 * Table component
 */
class Table extends Component {
  constructor(props) {
    super(props)
    bind(this, [
      'busy',
      'goto',
      'onChange',
      'onKeyDown',
      'refresh',
      'search',
      'watch',
    ])
    this.actions = ['busy', 'refresh', 'search', 'watch']
    this.state = {
      filters: {},
    }
  }

  render() {
    const busy = this.props.busy || this.props.forceBusy
    const { results, error } = this.props
    return (
      <div className={'Table ' + (busy ? 'busy' : 'active')}>
        {busy ? <Loading absolute="true" /> : ''}
        <BootstrapTable responsive>
          <thead className="main-table-head">{this.getHead()}</thead>
          <tbody>
            {!busy &&
              (error ? (
                <tr className="main-table-error">
                  <td colSpan={this.getColumnCount()}>{t(error)}</td>
                </tr>
              ) : (
                !results.length && (
                  <tr className="main-table-no-data">
                    <td colSpan={this.getColumnCount()}>{this.getNoData()}</td>
                  </tr>
                )
              ))}
            {this.getBody()}
          </tbody>
        </BootstrapTable>
        <Pagination {...this.getPagination()} onGoto={this.goto} />
      </div>
    )
  }

  componentDidMount() {
    this.reset()
    if (isFunction(this.props.init)) {
      this.props.init(this.getActions())
    }
  }

  componentDidUpdate({ count, limit, page, results }) {
    const last = Math.ceil(count / limit)
    if (count > 0 && !results.length && page !== last) {
      // When table is empty but there are still results, go to last page
      // This happens when user is deleting something from last page
      setTimeout(() => {
        this.goto(last)
      })
    }
  }

  componentWillUnmount() {
    actionCall(this.props.dispatch, tableReset)
    this.unwatch()
  }

  /**
   * Set table to busy
   */
  busy(busy = true) {
    actionCall(this.props.dispatch, tableBusy, busy)
  }

  getColumnCount() {
    return this.props.cols[0] || this.props.cols
  }

  getColumnRows() {
    return this.props.cols[1] || 1
  }

  /**
   * Get actions
   */
  getActions() {
    return pick(this, this.actions)
  }

  getBody() {
    return (this.props.results || []).map((item, row) => {
      return (
        <React.Fragment key={row}>
          <tr
            key={row}
            className={clsx({
              criteriaFirst: item.criteriaitem_id__criteria === 0,
              criteriaSecond: item.criteriaitem_id__criteria === 1,
            })}
          >
            {this.getColumns(item, row)}
          </tr>
          {this.getCommentColumn(item.comments)}
        </React.Fragment>
      )
    })
  }

  getColumns(item, row) {
    return range(this.getColumnCount()).map((column) => {
      return (
        <td key={column}>
          {this.props.cell(item, column, row, this.getActions())}
        </td>
      )
    })
  }

  getCommentColumn(comments = []) {
    if (comments.length > 0)
      return comments.map((comment) => {
        return (
          <tr
            key={comment.id}
            className="comment-lists"
            id={`comment-${comment.id}`}
          >
            <td colSpan="13">
              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                  <div className="main-box comment">
                    <div className="main-box-header clearfix p-0">
                      <span className="pull-left">
                        <i className="qf qf-chat"></i> {comment.name}
                      </span>
                      <span className="pull-right">
                        {moment(comment.created).format('YYYY-MM-DD HH:mm:ss')}
                      </span>
                    </div>
                    <div className="main-box-body clearfix p-0">
                      {comment.message}
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        )
      })
  }

  /**
   * Get extra query options
   */
  getExtraOptions() {
    if (isUndefined(this.props.extra)) {
      return {}
    }
    if (isFunction(this.props.extra)) {
      return this.props.extra() || {}
    }
    return this.props.extra || {}
  }

  /**
   * Get filters
   */
  getFilters() {
    const filters = {}
    forEach(this.state.filters, (value, key) => {
      if (!isEmpty(value)) {
        filters[key] = value
      }
    })
    return filters
  }

  /**
   * Get form date
   */
  getFormDate(name, options) {
    const date = (this.props.search[name] || '').split(' - '),
      value = options.range ? { from: date[0] || '', to: date[1] || '' } : date
    return (
      <DatePickerWrapper
        name={name}
        locale={this.props.lang}
        value={value}
        onChange={(e) => {
          this.input(
            e.target.name,
            options.range
              ? [e.target.value.from, e.target.value.to].join(' - ')
              : e.target.value
          )
        }}
      />
    )
  }

  /**
   * Get form select
   */
  getFormSelect(name, options) {
    if (options instanceof Map) {
      let i = 0
      let result = []
      options.forEach((value, key) => {
        result.push(
          <option key={i++} value={key}>
            {value}
          </option>
        )
      })
      return (
        <Form.Control
          name={name}
          as="select"
          onChange={this.onChange}
          defaultValue={this.props.search[name]}
        >
          {result.map((v) => v)}
        </Form.Control>
      )
    } else {
      return (
        <Form.Control
          name={name}
          as="select"
          onChange={this.onChange}
          defaultValue={this.props.search[name]}
        >
          {keys(options).map((key, i) => {
            return (
              <option key={i} value={key}>
                {options[key]}
              </option>
            )
          })}
        </Form.Control>
      )
    }
  }

  /**
   * Get form text
   */
  getFormText(name, options) {
    return (
      <Form.Control
        defaultValue={this.props.search[name]}
        name={name}
        placeholder={options.placeholder || ''}
        type="text"
        onChange={this.onChange}
        onKeyDown={this.onKeyDown}
      />
    )
  }

  getCountryCodeInput(name) {
    return (
      <CountryCodeSelect
        defaultValue={this.props.search[name]}
        onSelect={(code) => this.input(name, code)}
        prepend={false}
        clearOption
        allowAll
      />
    )
  }

  getVatSelect(name) {
    return (
      <VatSelect
        name={name}
        onChange={this.onChange}
        defaultValue={this.props.search[name]}
      />
    )
  }

  getHead() {
    return range(this.getColumnRows()).map((row) => {
      return (
        <tr key={row}>
          {range(this.getColumnCount()).map((column) => {
            return (
              <th key={column}>
                {this.getHeadCell(
                  this.props.head(column, row, this.getActions())
                )}
              </th>
            )
          })}
        </tr>
      )
    })
  }

  getHeadCell(th = '') {
    if (th.input) {
      if (th.vatSelect) {
        return this.getVatSelect(th.input)
      }
      if (th.countryCode) {
        return this.getCountryCodeInput(th.input)
      }
      if (th.select) {
        return this.getFormSelect(th.input, th.select)
      }
      if (th.date) {
        return this.getFormDate(th.input, th.date)
      }
      if (th.selectCombo) {
        return (
          <span className="select-combo">
            {this.getFormSelect(th.selectInput, th.selectCombo)}
            {this.getFormText(th.input, th)}
          </span>
        )
      }
      return this.getFormText(th.input, th)
    }
    if (th.sortable) {
      const { order, sort } = this.props,
        active = th.sortable === sort,
        index = ORDERS.indexOf(order || ''),
        next = active
          ? ORDERS[index >= ORDERS.length - 1 ? 0 : index + 1]
          : 'asc',
        icon = ['arrow_drop_up', 'arrow_drop_down']
      if (active) {
        if (order === 'asc') {
          icon.pop()
        } else if (order === 'desc') {
          icon.shift()
        }
      }
      return (
        <span
          className={
            'sortable' +
            (active ? ' active' : '') +
            (active && order ? ' ' + order : ' default')
          }
          onClick={() => {
            this.sort(th.sortable, next)
          }}
        >
          {th.title} <Icon value={icon} />
        </span>
      )
    }
    return <span>{th.title || th}</span>
  }

  getNoData() {
    return this.props.title
      ? t(`No ${this.props.title.toLowerCase()}`)
      : t(`No data`)
  }

  /**
   * Get pagination
   */
  getPagination() {
    return pick(this.props, ['count', 'limit', 'page'])
  }

  /**
   * Go to page
   */
  goto(page, limit) {
    this.query({ page, limit })
  }

  /**
   * Input
   */
  input(name, value) {
    this.setState((state) => {
      return extend({}, state, {
        filters: extend({}, state.filters, {
          [name]: value,
        }),
      })
    })
  }

  onChange(e) {
    this.input(e.target.name, e.target.value)
  }

  onKeyDown(e) {
    if (e.keyCode === 13) {
      this.search()
    }
  }

  /**
   * Query
   */
  query(options = {}) {
    const { action, filters } = this.props
    const fields = ['page', 'limit', 'search', 'sort', 'order']
    const extra = this.getExtraOptions()
    if (filters) {
      options.search = {
        ...(options.search || {}),
        ...filters,
      }
    }
    actionCall(
      this.props.dispatch,
      tableQuery,
      extend(
        {},
        pick(this.props, fields),
        pickBy(options, (value, key) => {
          return fields.indexOf(key) >= 0 && !isUndefined(value)
        }),
        { extra },
        { action }
      )
    )
  }

  /**
   * Refresh
   */
  refresh() {
    this.query()
  }

  /**
   * Reset
   */
  reset() {
    this.query({
      page: 1,
      search: {},
    })
  }

  /**
   * Search
   */
  search() {
    this.query({
      page: 1,
      search: this.getFilters(),
    })
  }

  /**
   * Sort
   */
  sort(sort, order) {
    this.query({ sort, order })
  }

  /**
   * Unwatch
   */
  unwatch() {
    if (this.watcher && isFunction(this.watcher.cancel)) {
      this.watcher.cancel()
    }
  }

  /**
   * Watch actions
   */
  watch(actions, listener) {
    this.unwatch()
    this.watcher = watch(actions, listener)
  }
}

export default connect(
  ({ table }) => table,
  null,
  // mergeProps function from redux
  (stateProps, dispatchProps, ownProps) => {
    return {
      ...ownProps, // If you provide `limit` prop on Table component it overrides the default
      ...stateProps,
      ...dispatchProps,
      limit: tableState.limit || ownProps.limit, // By default always set limit provided on reducer.js
    }
  }
)(Table)
