import fileDownload from 'js-file-download'
import parsePhoneNumber from 'libphonenumber-js'
import {
  capitalize,
  isEmpty,
  isFunction,
  isUndefined,
  keys as unsortedKeys,
  range,
} from 'lodash'
import moment from 'moment'
import * as Yup from 'yup'

import { setNotification } from '../common/notification/Notification'
import { history } from '../routes'

export const EMAIL_REGEX = /\S+@\S+\.\S+/
export const MOBILE_REGEX =
  /^[+]?[(]?[0-9]{1,3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{1,8}$/im
export const CSV_PHONENUMBER_LENGTH = 9

/**
 * Bind
 */
export function bind(object, methods = []) {
  methods.forEach((method) => {
    if (!isFunction(object[method])) {
      throw new Error('Invalid method: ' + method)
    }
    object[method] = object[method].bind(object)
  })
}

/**
 * Call
 */
export function call(callback, ...args) {
  return (e) => {
    if (isFunction(callback)) {
      return callback(...args, e)
    }
  }
}

/**
 * Fake async
 */
export function fakeAsync(data, callback, fail, timeout = 200) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (isFunction(data)) {
        data = data()
      }
      if (isFunction(callback)) {
        callback()
      }
      if (fail) {
        reject(fail)
      } else {
        resolve(data)
      }
    }, timeout)
  })
}

/**
 * Query string to object
 */
export function fromQuery(url) {
  const question = url.indexOf('?')
  if (question < 0) {
    return {}
  }
  const object = {},
    hash = url.indexOf('#', question),
    query = url.substring(question + 1, hash < 0 ? undefined : hash)
  query.split('&').forEach((pair) => {
    const [name, value] = pair.split('=')
    object[name] = isUndefined(value) ? true : decodeURIComponent(value)
  })
  return object
}

/**
 * Get recursive
 */
export function getRecursive(object, names, value) {
  if (names.length === 1) {
    return {
      ...object,
      [names[0]]: value,
    }
  } else {
    const name = names.shift()
    return {
      ...object,
      [name]: getRecursive(object[name], names, value),
    }
  }
}

/**
 * Is mobile
 */
export function isMobile(text) {
  const allowedCharacters = ['(', ')', ' ', '-'],
    textString = text.toString()
  if (allowedCharacters.some((str) => textString.includes(str))) {
    return MOBILE_REGEX.test(text.replace(/[()-\s]/g, ''))
  } else {
    return MOBILE_REGEX.test(text)
  }
}

export function modifyPhoneNumber(text, fileType) {
  let parsed = ''
  let number = text.toString()
  if (fileType === 'csv') {
    if (number.startsWith('0')) {
      number = '+358' + number.substring(1, number.length)
    } else if (text.toString().length === CSV_PHONENUMBER_LENGTH) {
      number = '+358' + number
    } else {
      number = '+' + number
    }
    parsed = parsePhoneNumber(`Phone: ${number}`, 'FIN')
  } else {
    parsed = parsePhoneNumber(`Phone: ${number}`, 'FIN')
    if (isUndefined(parsed) && isFunction(number.replace)) {
      if (number.replace(/\D/g, '').match(/^0/)) {
        number = '+358' + number.replace(/\D/g, '').substring(1)
      } else {
        number = '+' + number.replace(/\D/g, '')
      }

      return number
    }
  }
  return parsed?.number
}

export function isEmailAddress(text) {
  return EMAIL_REGEX.test(text)
}

export function modifyEmail(text) {
  return EMAIL_REGEX.test(text) ? text : undefined
}
/**
 * Keys
 */
export function keys(object) {
  const items = unsortedKeys(object)
  if (items[items.length - 1] === '') {
    items.unshift(items.pop())
  }
  return items
}

/**
 * Stop propagation on event
 */
export function freeze(callback, ...args) {
  return (e) => {
    e.stopPropagation()
    e.preventDefault()
    if (isFunction(callback)) {
      callback(...args)
    }
  }
}

/**
 * Map array to object key values
 */
export function mapKeyValues(array, key, value) {
  const object = {}
  array.forEach((item) => {
    object[item[key]] = item[value]
  })
  return object
}

export function formSubmitKeyDown(keyEvent) {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault()
  }
}

// message-counter.js scripts from Gabriel
/* eslint-disable */
const GSM =
  /[@£$¥èéùìòç\nØø\rÅåΔ_ΦΓΛΩΠΨΣΘΞ\xA0ÆæßÉ !"#¤%&'()*+,\-.\/0-9:;<=>?¡A-ZÄÖÑÜ§¿a-zäöñüà]/g
const GSMEXT = /[\f^{}\\[~\]|€]/g
const NBSP = /\u00a0/g
/* eslint-enable */

export function gsmCharCount(text) {
  if (!text) return 0
  if (text.match(NBSP)) return -1

  var gsm1 = (text.match(GSM) || []).length

  if (gsm1 === text.length) return gsm1

  var gsm2 = (text.match(GSMEXT) || []).length

  if (gsm1 + gsm2 !== text.length) return -1

  return gsm1 + 2 * gsm2
}

export function gsmMessageCount(text) {
  var count = gsmCharCount(text)

  if (count < 2) return count

  if (count <= 160) return 1

  return Math.ceil(count / 153)
}

export function ucsMessageCount(text) {
  if (!text) return 0

  if (text.length <= 70) return 1

  return Math.ceil(text.length / 67)
}

export function messageCount(text) {
  var count = gsmMessageCount(text)
  if (count !== -1) return [count, 'GSM']

  return [ucsMessageCount(text), 'Unicode']
}

export function validateNumber(text) {
  var matches = text.match(/^(\+|00)[1-9][0-9]{4,14}$/)
  if (matches) return [matches[1].length + 5, matches[1].length + 15]
  matches = text.match(/^[0-9]{1,15}$/)
  if (matches || text.length === 0) return [1, 15]

  return null
}

export function validateSender(text) {
  return validateNumber(text) || [1, 11]
}

// end message-counter.js

export function randomPassword(length) {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length))
  }

  return result
}

export function validateDateTime(value, date, time = '') {
  const dateTimeSelected = moment(`${date} ${value}`, 'DD.MM.YYYY HH:mm')
  if (time) {
    const endDateTimeSelected = moment(`${date} ${time}`, 'DD.MM.YYYY HH:mm')
    if (endDateTimeSelected.isAfter(dateTimeSelected)) {
      value = time
    }
  } else {
    if (dateTimeSelected.isBefore(moment())) {
      value = moment().format('HH:mm')
    }
  }
  return value
}

export function getLastUrlSegment(pathname) {
  if (typeof pathname === 'string' || pathname instanceof String) {
    return pathname.substring(pathname.lastIndexOf('/') + 1)
  }
}

export function fileDownloader({
  result,
  filename,
  type,
  message = 'No data to download',
}) {
  const ext = type === 'excel' ? 'xlsx' : type
  if (type === 'excel' && result.byteLength) {
    fileDownload(result, `${filename}.${ext}`)
  } else if ((type === 'xlsx' || type === 'xls') && result !== '') {
    fileDownload(result, `${filename}.xls`)
  } else if (type === 'csv' && result !== '') {
    fileDownload(result, `${filename}.${ext}`)
  } else {
    setNotification({
      variant: 'warning',
      message,
    })
  }
}

export function isOldIe() {
  const ua = window.navigator.userAgent
  return ua.indexOf('MSIE') > 0
}

export function isIe11() {
  const ua = window.navigator.userAgent
  return ua.indexOf('Trident') > 0
}

export function parseBool(val) {
  if (
    (typeof val === 'string' &&
      (val.toLowerCase() === 'true' || val.toLowerCase() === 'yes')) ||
    val === 1
  )
    return true
  else if (
    (typeof val === 'string' &&
      (val.toLowerCase() === 'false' || val.toLowerCase() === 'no')) ||
    val === 0
  )
    return false

  return null
}

export function base64_encode(value) {
  return btoa(value).replace(/\+/g, '.').replace(/\//g, '_').replace(/=/g, '-')
}

export function base64_decode(value) {
  return atob(
    value
      .replace('._-', '+/=')
      .replace(/\./g, '+')
      .replace(/_/g, '/')
      .replace(/-/g, '=')
  )
}

export function updateUrlQuery(search) {
  try {
    if (!isEmpty(search)) {
      const encodedSearchHash = base64_encode(JSON.stringify(search))
      history.push({
        search: `?query=${encodedSearchHash}`,
      })
    } else {
      history.replace({
        search: '',
      })
    }
  } catch (error) {
    console.log(error)
  }
}

export function decodeUrlQuery(search) {
  try {
    const params = new URLSearchParams(search)
    return JSON.parse(base64_decode(params.get('query')))
  } catch (error) {
    console.log(error)
  }
}

/**
 * render customer mo number to option and set usage info for currently rendered mo number
 */
export function listSelectableCustomerMoMumber(t, listnumber, number) {
  const phonenumber = 0
  const inUse = 1
  if (number !== undefined && number === listnumber[phonenumber]) {
    listnumber[inUse] = false
  }
  return (
    <option key={listnumber[phonenumber]} value={listnumber[phonenumber]}>
      {listnumber[phonenumber]}
      {listnumber[inUse] ? ' (' + t('In use') + ')' : ''}
    </option>
  )
}

/**
 * Certain cases where customer mo number should not be selectable and is greyed we use this
 */
export function listCustomerMoNumber(t, listnumber, number) {
  const phonenumber = 0
  const inUse = 1
  if (number !== undefined && number === listnumber[phonenumber]) {
    listnumber[inUse] = false
  }
  return (
    <option
      key={listnumber[phonenumber]}
      value={listnumber[phonenumber]}
      disabled={listnumber[inUse]}
      className={listnumber[inUse] ? 'text-light' : ''}
    >
      {listnumber[phonenumber]}
      {listnumber[inUse] ? ' (' + t('In use') + ')' : ''}
    </option>
  )
}

export function parse_info_text(text, t) {
  let parsed = ''

  text.split(' ').forEach((item, index, arr) => {
    if (item[0] === '[') {
      item.split(',').forEach((item, index) => {
        if (index !== arr.length - 1) {
          parsed = parsed.concat(
            ' ',
            t(item.replace('[', '').replace(']', '')),
            ','
          )
        } else {
          parsed = parsed.concat('', t(item.replace('[', '').replace(']', '')))
        }
      })
    } else {
      if (!isNaN(item[0])) {
        parsed = parsed.concat(' ', item)
      } else {
        parsed = parsed.concat(' ', t(item))
      }
    }
  })

  return parsed
}

export const messageInputPlaceholder = (type, t) => {
  let text = ''
  switch (type) {
    case 'NPS':
      text =
        'Example; What is your opinion on our service? Rank aswering from 0-10'
      break

    default:
      text = ''
      break
  }

  return t(text)
}

export const isCustomStyled = () => {
  return process.env.REACT_APP_CUSTOM_ENABLED === 'true'
}

// Validation utils
export const requiredMsg = 'Please fill in the required field'
export const invalidMsg = 'Invalid value'
export const requiredString = Yup.string().required(requiredMsg)
export const positiveNumber = Yup.number().positive(
  'Please input a positive number'
)

export const endDateNotBeforeBeginDate = (value, { options }) => {
  if (value === null) {
    return true
  }
  const { getApplicationValue } = options.context
  if (!getApplicationValue) {
    console.warn('Validation context does not include getApplicationValue')
    return true
  }
  const begin = getApplicationValue('begin')
  return moment(begin) < moment(value)
}

export const getContactEmail = () => {
  return isCustomStyled() ? 'tiedonvalitys@elisa.fi' : 'support@quriiri.fi'
}

export const getBillingIntervalOptions = () => range(1, 13)

export const enumToTranslationKey = (value) =>
  typeof value === 'string' ? capitalize(value.replaceAll('_', ' ')) : ''

export const parseIntIfNotNumber = (value) => {
  if (typeof value === 'number') {
    return value
  }
  return parseInt(value)
}

// Date and time utils
export const changeDateTimeStringFormat = (value, from, to) =>
  moment(value, from).format(to)
export const formatDate = (dateString, format) =>
  moment(dateString).format(format)

export const getStartOfDay = (value) => moment(value).startOf('day')

export const getDateRangeCurrentMonth = () =>
  `${moment().startOf('month').format('DD.MM.YYYY')} - ${moment()
    .endOf('month')
    .format('DD.MM.YYYY')}`

// Notification utils
export const displayErrorToast = (message, cb) =>
  setNotification({
    variant: 'error',
    message,
    cb,
  })

export const displaySuccessToast = (message, cb) =>
  setNotification({
    variant: 'success',
    message,
    cb,
  })
