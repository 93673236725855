import * as faker from 'faker'
import {
  isNull,
  isUndefined,
  keys,
  padStart,
  random,
  range,
  sample,
  sampleSize,
  upperFirst,
} from 'lodash'
import moment from 'moment'

import { bind, fakeAsync } from '../common/utils'
import { fileDownloader } from '../common/utils'
import { getPageNumber } from './'

export const genders = {
  MALE: 'Male',
  FEMALE: 'Female',
}

export const statuses = {
  ACTIVE: 'Active',
  PASSIVE: 'Passive',
  IN_USE: 'In Use',
}

export const receiveSMS = {
  true: 'Yes',
  false: 'No',
  //limited: 'Limited',  Remove comment after backend is merged
}

export const weekdaysBitwise = ['1', '2', '4', '8', '16', '32', '64']

/**
 * Contact API
 */
export class Contact {
  constructor(api) {
    this.api = api
    bind(this, [
      'deleteContact',
      'deleteGroup',
      'deleteContactNote',
      'downloadContacts',
      'getContact',
      'getContacts',
      'getListContacts',
      'getContactColumns',
      'getContactNotes',
      'getGroup',
      'getGroups',
      'getContactUploadStatus',
      'removeContact',
      'resetFailedSms',
      'saveContact',
      'saveContactUpload',
      'saveContactNote',
      'saveGroup',
      'saveJoinContacts',
      'abortUpload',
      'getContactUploadFailures',
      'downloadContactNotes',
      'saveJoinAllContacts',
    ])
  }

  /**
   * Delete contact
   */
  deleteContact({ id, customerId, options }) {
    if (isUndefined(id)) {
      return this.api
        .delete(`service/contacts`, {}, true, {
          'X-Customer': customerId,
          ...this.api.withFilters(options, {
            selected: 'selected',
            all: 'all',
          }),
        })
        .then(() => {
          return {
            success: true,
          }
        })
    } else {
      return this.api
        .delete(`service/contacts/${id}`, {}, true, {
          'X-Customer': customerId,
        })
        .then(() => {
          return {
            success: true,
          }
        })
    }
  }

  deleteContactNote({ id, customerId, options }) {
    return this.api
      .delete(`service/contactnotes/${id}`, {}, true, {
        'X-Customer': customerId,
        ...this.api.withFilters(options, {
          noteid: 'noteid',
          all: 'all',
        }),
      })
      .then(() => {
        return {
          success: true,
        }
      })
  }

  /**
   * Delete group
   */
  deleteGroup({ customerId, options }) {
    return this.api
      .delete(`service/groups`, {}, true, {
        'X-Customer': customerId,
        ...this.api.withFilters(options, {
          groupid: 'groupid',
          all: 'all',
        }),
      })
      .then((response) => response)
  }

  /**
   * Get contact notes
   */
  getContactNotes(options = {}) {
    return this.api
      .get(
        `service/contactnotes/${options.extra.contactId}?page=` +
          getPageNumber(options),
        {},
        true,
        {
          'X-Customer': options.extra.customerId,
          ...this.api.withFilters(options, {
            userFirstName: 'user__first_name',
            userLastName: 'user__last_name',
            note: 'note',
            created: 'created_range',
          }),
        }
      )
      .then(({ count, results }) => {
        return {
          count,
          results: results.map((result) => {
            return {
              id: result.id,
              created: result.created,
              note: result.note,
              userFirstName:
                !isNull(result.user) && !isUndefined(result.user)
                  ? result.user.first_name
                  : result.first_name || '',
              userLastName:
                !isNull(result.user) && !isUndefined(result.user)
                  ? result.user.last_name
                  : result.last_name || '',
            }
          }),
        }
      })
  }
  /**
   * Get single contact by ID
   */
  getContact({ id, customerId }) {
    return this.api
      .get(`service/contacts/${id}`, {}, true, {
        'X-Customer': customerId,
      })
      .then((result) => {
        return {
          id: result.id,
          mobilePhone: result.msisdn,
          firstName: result.first_name,
          lastName: result.last_name,
          email: result.email,
          customerNumber: result.customernumber,
          address: result.address,
          postalCode: result.postalcode,
          city: result.city,
          birthday:
            result.birthday !== null
              ? moment(result.birthday, 'YYYY-MM-DD').format('DD.MM.YYYY')
              : undefined,
          gender: result.gender,
          parameter1: result.param1,
          parameter2: result.param2,
          parameter3: result.param3,
          parameter4: result.param4,
          receiveSmsMessages: result.receivesms
            ? 'normalSmsMessages'
            : isUndefined(result.limited_receivesms)
            ? 'disabledSmsMessages'
            : 'limitedSmsMessages',
          smsSendingFailed: result.failedsms_count,
          created: result.created,
          limitedReceivesms: !isUndefined(result.limited_receivesms)
            ? {
                begindate:
                  result.limited_receivesms.begindate !== null
                    ? moment(
                        result.limited_receivesms.begindate,
                        'YYYY-MM-DD'
                      ).format('DD.MM.YYYY')
                    : undefined,
                enddate:
                  result.limited_receivesms.enddate !== null
                    ? moment(
                        result.limited_receivesms.enddate,
                        'YYYY-MM-DD'
                      ).format('DD.MM.YYYY')
                    : undefined,
                begintime:
                  result.limited_receivesms.begintime !== null
                    ? moment(
                        result.limited_receivesms.begintime,
                        'HH:mm:ss'
                      ).format('HH:mm')
                    : undefined,
                endtime:
                  result.limited_receivesms.endtime !== null
                    ? moment(
                        result.limited_receivesms.endtime,
                        'HH:mm:ss'
                      ).format('HH:mm')
                    : undefined,
                excludeHolidays: result.limited_receivesms.exclude_holidays,
                weekdaysRange: this.getWeekdaysRange(
                  result.limited_receivesms.weekdays
                ),
              }
            : undefined,
        }
      })
  }

  getWeekdaysRange(weekdayValue) {
    if (weekdayValue === 127) {
      return weekdaysBitwise
    } else if (weekdaysBitwise.includes(weekdayValue)) {
      return Array.from(String(weekdayValue), String)
    } else {
      //Clean this
      const weekayArray = []
      if (weekdayValue / 64 >= 1) {
        weekayArray.push('64')
        weekdayValue = weekdayValue - 64
      }
      if (weekdayValue / 32 >= 1) {
        weekayArray.push('32')
        weekdayValue = weekdayValue - 32
      }
      if (weekdayValue / 16 >= 1) {
        weekayArray.push('16')
        weekdayValue = weekdayValue - 16
      }
      if (weekdayValue / 8 >= 1) {
        weekayArray.push('8')
        weekdayValue = weekdayValue - 8
      }
      if (weekdayValue / 4 >= 1) {
        weekayArray.push('4')
        weekdayValue = weekdayValue - 4
      }
      if (weekdayValue / 2 >= 1) {
        weekayArray.push('2')
        weekdayValue = weekdayValue - 2
      }
      if (weekdayValue / 1 === 1) {
        weekayArray.push('1')
      }
      return weekayArray
    }
  }

  /**
   * Get contacts
   */
  getContacts(options = {}) {
    return this.api
      .get(`service/contacts?page=${getPageNumber(options)}`, {}, true, {
        'X-Customer': options.extra.customerId,
        ...this.api.withFilters(
          { ...options, groupid: options.extra.group },
          {
            first_name: 'first_name',
            last_name: 'last_name',
            msisdn: 'msisdn',
            email: 'email',
            customernumber: 'customernumber',
            address: 'address',
            postalcode: 'postalcode',
            city: 'city',
            birthday: 'birthday',
            gender: 'gender',
            param1: 'param1',
            param3: 'param3',
            param4: 'param4',
            receivesms: 'receivesms',
            failedsms_count: 'failedsms_count',
            phone: 'phone',
            created: 'created',
          }
        ),
      })
      .then(({ count, results }) => {
        const response = options.extra.exclude
          ? results.filter(
              (contact) => !options.extra.exclude.includes(contact.id)
            )
          : results
        return {
          count,
          results: response,
        }
      })
  }

  getListContacts(options = {}) {
    return this.api
      .get('service/listcontacts?page=' + getPageNumber(options), {}, true, {
        'X-Customer': options.extra.customerId,
        ...this.api.withFilters(
          {
            ...options,
            groupid: options.extra.group.id,
            exclude_groupcontacts: true,
          },
          {
            first_name: 'list_first_name',
            last_name: 'list_last_name',
            msisdn: 'list_msisdn',
            email: 'list_email',
            groupid: 'groupid',
            created: 'created',
          }
        ),
      })
      .then(({ count, results }) => {
        return {
          count,
          results,
        }
      })
  }

  /**
   * Get group
   */
  getGroup({ id, customerId, applicationevent_key }) {
    if (isUndefined(applicationevent_key)) {
      return this.api
        .get(`service/group/${id}`, {}, true, {
          'X-Customer': customerId,
        })
        .then((result) => {
          return {
            id: result.id,
            member_count: result.member_count,
            recipient_count: result.recipient_count,
            status: result.status,
            name: result.name,
            created: result.created,
            notification_conf: result.notification_conf,
          }
        })
    } else {
      return this.api
        .get(
          `service/group/${id}?applicationevent_key=${applicationevent_key}`,
          {},
          true,
          {
            'X-Customer': customerId,
          }
        )
        .then((result) => {
          return {
            id: result.id,
            member_count: result.member_count,
            recipient_count: result.recipient_count,
            status: result.status,
            name: result.name,
            created: result.created,
            notification_conf: result.notification_conf,
          }
        })
    }
  }

  /**
   * Get filtered contacts
   */
  getFilteredContacts(search, group, exclude) {
    const fields = search ? keys(search) : []
    return this.getMockContacts().filter((contact) => {
      if (group && group.contacts.indexOf(contact.id) < 0) {
        return false
      }
      if (exclude && exclude.indexOf(contact.id) >= 0) {
        return false
      }
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i]
        switch (field) {
          case 'status':
            if (group) {
              switch (search[field]) {
                case 'active':
                  if (group.contacts.indexOf(contact.id) < 0) {
                    return false
                  }
                  break
                case 'inactive':
                  if (group.contacts.indexOf(contact.id) >= 0) {
                    return false
                  }
                  break
                default:
                  break
              }
            }
            break
          default:
            if (
              (contact[field] || '')
                .toLowerCase()
                .indexOf((search[field] || '').toLowerCase()) < 0
            ) {
              return false
            }
            break
        }
      }
      return true
    })
  }

  /**
   * Get filtered groups
   */
  getFilteredGroups(search) {
    const fields = search ? keys(search) : []
    return this.getMockGroups().filter((group) => {
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i]
        switch (field) {
          default:
            if (
              (group[field] || '')
                .toLowerCase()
                .indexOf((search[field] || '').toLowerCase()) < 0
            ) {
              return false
            }
            break
        }
      }
      return true
    })
  }

  /**
   * Get groups
   */
  getGroups(options = {}) {
    return this.api
      .get('service/groups?page=' + getPageNumber(options), {}, true, {
        'X-Customer': options.extra.customerId,
        ...this.api.withFilters(
          {
            ...options,
            status: options.extra.status,
            contactid: options.extra.contactId,
          },
          {
            name: 'name',
            status: 'status',
            created: 'created',
            member_count: 'member_count',
            recipient_count: 'recipient_count',
          }
        ),
      })
      .then((results) => {
        return {
          count: results.count,
          contactGroups: results.contactgroups || [],
          results: results.groups.map((result) => {
            return {
              id: result.id,
              name: result.name,
              member_count: result.member_count,
              recipient_count: result.recipient_count,
              users: result.member_count,
              status: result.status,
              created: result.created,
            }
          }),
        }
      })
  }

  /**
   * Get mock contacts
   */
  getMockContacts() {
    const id = this.api.user.getCurrentUser().id
    if (!this.contacts) {
      this.contacts = {}
    }
    if (!this.contacts[id]) {
      if (!this.lastContactId) {
        this.lastContactId = 0
      }
      this.contacts[id] = [
        ...range(50).map((i) => {
          return {
            id: ++this.lastContactId + '',
            mobilePhone: faker.phone.phoneNumber(),
            firstName: faker.name.firstName(),
            lastName: faker.name.lastName(),
            email: faker.internet.email().toLowerCase(),
            customerNumber: faker.address.zipCode(),
            address: faker.address.streetAddress(),
            postalCode: faker.address.zipCode(),
            city: faker.address.city(),
            birthday: faker.date.past(60),
            gender: sample(keys(genders)),
            parameter1: '',
            parameter2: '',
            parameter3: '',
            parameter4: '',
            receiveSmsMessages: sample([true, false]),
            smsSendingFailed: random(0, 120),
            created: faker.date.recent(90),
          }
        }),
      ]
      this.lastId = this.contacts.length
    }
    return this.contacts[id]
  }

  /**
   * Get mock groups
   */
  getMockGroups() {
    const id = this.api.user.getCurrentUser().id
    if (!this.groups) {
      this.groups = {}
    }
    if (!this.groups[id]) {
      if (!this.lastGroupId) {
        this.lastGroupId = 0
      }
      this.groups[id] = range(random(5, 15)).map((i) => {
        const size = random(5, 30),
          contacts = sampleSize(this.getMockContacts(), size).map(
            ({ id }) => id
          )
        return {
          id: ++this.lastGroupId + '',
          contacts,
          created: faker.date.recent(90),
          name: upperFirst(faker.lorem.words(random(2, 5))),
          notifications: range(random(0, 5)).map(
            () => '+358' + padStart(random(10000000, 999999999), 9, '0')
          ),
          size,
          status: sample(keys(statuses)),
        }
      })
    }
    return this.groups[id]
  }

  /**
   * Reset failed SMS
   */
  resetFailedSms({ id }) {
    const contact = this.getMockContacts().find((child) => id === child.id)
    if (contact) {
      contact.smsSendingFailed = 0
    }
    return fakeAsync(contact)
  }

  /**
   * Save contact
   */
  saveContact(contact) {
    const headers = { 'X-Customer': contact.customerId }
    const response = (result) => {
      return {
        id: result.id,
        mobilePhone: result.msisdn,
        firstName: result.first_name,
        lastName: result.last_name,
        email: result.email,
        customerNumber: result.customernumber,
        address: result.address,
        postalCode: result.postalcode,
        city: result.city,
        birthday: result.birthday,
        gender: result.gender,
        parameter1: result.param1,
        parameter2: result.param2,
        parameter3: result.param3,
        parameter4: result.param4,
        receiveSmsMessages: result.receivesms
          ? 'normalSmsMessages'
          : isUndefined(result.limited_receivesms)
          ? 'disabledSmsMessages'
          : 'limitedSmsMessages',
        smsSendingFailed: result.failedsms_count,
        created: result.created,
        limitedReceivesms: !isUndefined(result.limited_receivesms)
          ? {
              begindate:
                result.limited_receivesms.begindate !== null
                  ? moment(
                      result.limited_receivesms.begindate,
                      'YYYY-MM-DD'
                    ).format('DD.MM.YYYY')
                  : undefined,
              enddate:
                result.limited_receivesms.enddate !== null
                  ? moment(
                      result.limited_receivesms.enddate,
                      'YYYY-MM-DD'
                    ).format('DD.MM.YYYY')
                  : undefined,
              begintime:
                result.limited_receivesms.begintime !== null
                  ? moment(
                      result.limited_receivesms.begintime,
                      'HH:mm:ss'
                    ).format('HH:mm')
                  : undefined,
              endtime:
                result.limited_receivesms.endtime !== null
                  ? moment(
                      result.limited_receivesms.endtime,
                      'HH:mm:ss'
                    ).format('HH:mm')
                  : undefined,
              excludeHolidays: result.limited_receivesms.exclude_holidays,
              weekdaysRange: this.getWeekdaysRange(
                result.limited_receivesms.weekdays
              ),
            }
          : {},
      }
    }

    if (contact.id) {
      return this.api
        .put(
          `service/contacts/${contact.id}`,
          {
            email: contact.email,
            msisdn: contact.mobilePhone,
            first_name: contact.firstName,
            last_name: contact.lastName,
            customernumber:
              contact.customerNumber === '' ? null : contact.customerNumber,
            gender: contact.gender === '' ? null : contact.gender,
            address: contact.address === '' ? null : contact.address,
            birthday:
              contact.birthday === undefined ||
              !moment(contact.birthday).isValid()
                ? null
                : moment(contact.birthday, 'DD.MM.YYYY').format('YYYY-MM-DD'),
            postalcode: contact.postalCode === '' ? null : contact.postalCode,
            city: contact.city === '' ? null : contact.city,
            param1: contact.parameter1 === '' ? null : contact.parameter1,
            param2: contact.parameter2 === '' ? null : contact.parameter2,
            param3: contact.parameter3 === '' ? null : contact.parameter3,
            param4: contact.parameter4 === '' ? null : contact.parameter4,
            receivesms:
              contact.receiveSmsMessages !== 'limitedSmsMessages' &&
              contact.receiveSmsMessages !== 'disabledSmsMessages'
                ? true
                : contact.receiveSmsMessages === 'limitedSmsMessages'
                ? false
                : false,
            resetfailed: contact.resetFailed,
            limited_receivesms:
              contact.receiveSmsMessages === 'limitedSmsMessages'
                ? {
                    begindate:
                      contact.limitedReceivesms.begindate === undefined ||
                      !moment(
                        contact.limitedReceivesms.begindate,
                        'DD.MM.YYYY'
                      ).isValid()
                        ? null
                        : moment(
                            contact.limitedReceivesms.begindate,
                            'DD.MM.YYYY'
                          ).format('YYYY-MM-DD'),
                    enddate:
                      contact.limitedReceivesms.enddate === undefined ||
                      !moment(
                        contact.limitedReceivesms.enddate,
                        'DD.MM.YYYY'
                      ).isValid()
                        ? null
                        : moment(
                            contact.limitedReceivesms.enddate,
                            'DD.MM.YYYY'
                          ).format('YYYY-MM-DD'),
                    begintime:
                      contact.limitedReceivesms.begintime === undefined
                        ? undefined
                        : moment(
                            contact.limitedReceivesms.begintime,
                            'HH:mm'
                          ).format('HH:mm'),
                    endtime:
                      contact.limitedReceivesms.endtime === undefined
                        ? undefined
                        : moment(
                            contact.limitedReceivesms.endtime,
                            'HH:mm'
                          ).format('HH:mm'),
                    weekdays: contact.weekdays,
                    exclude_holidays: contact.limitedReceivesms.excludeHolidays,
                  }
                : undefined,
          },
          true,
          headers
        )
        .then(response)
    } else {
      return this.api
        .post(
          'service/contacts',
          {
            email: contact.email,
            msisdn: contact.mobilePhone,
            first_name: contact.firstName,
            last_name: contact.lastName,
            customernumber: contact.customerNumber,
            groupid: contact.groupId,
          },
          true,
          headers
        )
        .then(response)
    }
  }

  saveContactUpload({ customerId, filename, body }) {
    const headers = {
      'content-type': 'multipart/form-data',
      'X-Customer': customerId,
    }
    return this.api
      .put(`service/contactupload/${filename}`, body, true, headers)
      .then((response) => {
        return {
          uploadId: response.uploadid,
          groupId: response.groupid,
        }
      })
  }

  getContactUploadStatus({ customerId, uploadId }) {
    const headers = {
      'X-Customer': customerId,
    }
    return this.api
      .get(`service/contactuploadstatus/${uploadId}`, {}, true, headers)
      .then((response) => {
        return {
          step: response.step,
          status: response.status,
          total: response.total_rows,
          failed: response.failed_rows,
          success: response.success_rows,
          totalNew: response.new_contacts,
          totalUpdated: response.updated_contacts,
          totalDuplicate: response.duplicate_contacts,
        }
      })
  }

  abortUpload({ customerId, uploadId }) {
    const headers = {
      'X-Customer': customerId,
    }
    return this.api
      .post(
        `service/abortupload`,
        {
          uploadid: uploadId,
        },
        true,
        headers
      )
      .then((response) => {
        return {
          total: response.total_rows,
          failed: response.failed_rows,
          success: response.success_rows,
          totalNew: response.new_contacts,
          totalUpdated: response.updated_contacts,
          totalDuplicate: response.duplicate_contacts,
        }
      })
  }

  /**
   * Save group
   */
  saveGroup(group) {
    const headers = {
      'X-Customer': group.customerId,
    }
    if (group.id) {
      return this.api
        .put(
          `service/group/${group.id}`,
          {
            id: group.id,
            name: group.name,
            status: group.status,
            member_count: group.member_count,
            notification_conf: {
              msisdns: group.phoneTags,
              emails: group.emailTags,
              contacts: group.contactTags,
            },
          },
          true,
          headers
        )
        .then((result) => {
          return {
            id: result.id,
            member_count: result.member_count,
            recipient_count: result.recipient_count,
            status: result.status,
            created: result.created,
            name: result.name,
            notification_conf: result.notification_conf,
          }
        })
    } else {
      const { contacts } = group

      return this.api
        .post(
          'service/groups',
          {
            name: group.name,
            groupid: group.groupId,
            ...contacts,
          },
          true,
          headers
        )
        .then(({ id }) => {
          return {
            new: true,
            id,
          }
        })
    }
  }

  saveJoinContacts({ customerId, groupId, contacts }) {
    const headers = {
      'X-Customer': customerId,
    }
    return this.api
      .post(
        'service/listjoincontacts',
        {
          groupid: groupId,
          join_contactid: contacts,
        },
        true,
        headers
      )
      .then((response) => response)
  }

  saveJoinAllContacts({ customerId, groupId }) {
    const headers = {
      'X-Customer': customerId,
    }
    return this.api
      .post(
        'service/listjoinallcontacts',
        {
          groupid: groupId,
        },
        true,
        headers
      )
      .then((response) => response)
  }

  saveContactNote(contact) {
    const headers = {
      'X-Customer': contact.customerId,
    }
    return this.api
      .post(
        `service/contactnotes/${contact.id}`,
        {
          note: contact.note,
        },
        true,
        headers
      )
      .then(() => {
        return {
          new: true,
        }
      })
  }

  addContactGroup(contact) {
    const headers = {
      'X-Customer': contact.customerId,
    }
    return this.api
      .put(
        `service/contacts/${contact.id}`,
        {
          groupid: contact.groupId,
          msisdn: contact.mobilePhone,
        },
        true,
        headers
      )
      .then(() => {
        return { success: true }
      })
  }

  removeContact(group) {
    const headers = {
      'X-Customer': group.customerId,
    }
    return this.api
      .put(`service/group/${group.id}/contacts`, group.body, true, headers)
      .then(() => {
        return { success: true }
      })
  }

  getContactColumns(request) {
    const { columns, customerId } = request
    return this.api
      .get('service/contactscolumns', {}, true, {
        'X-Customer': customerId,
        'X-Filter': JSON.stringify(columns),
      })
      .then((response) => {
        return response
      })
  }

  downloadContacts({ customerId, filter, message }) {
    const { download } = filter
    return this.api
      .get(
        'service/downloadcontacts',
        {},
        true,
        {
          'X-Customer': customerId,
          'X-filter': JSON.stringify(filter),
        },
        download === 'excel' ? true : false
      )
      .then((result) => {
        fileDownloader({
          result,
          filename: 'contacts',
          type: download,
          message: message,
        })
      })
  }

  findContact(request) {
    const { customerId, msisdn } = request

    return this.api
      .get(`service/contact/find/${msisdn}`, {}, true, {
        'X-Customer': customerId,
      })
      .then((response) => response)
  }

  getContactUploadFailures({ customerId, uploadId, fileType }) {
    const headers = {
      'X-Customer': customerId,
    }
    return this.api
      .get(
        `service/contactupload/failed/${uploadId}`,
        {},
        true,
        headers,
        fileType === 'xls' || fileType === 'xlsx' ? true : false
      )
      .then((result) => {
        fileDownloader({
          result,
          filename: 'FailedRows',
          type: fileType,
        })
      })
  }

  downloadContactNotes({ customerId, filter, message, userId }) {
    const { download } = filter
    return this.api
      .get(
        `service/downloadcontactnotes/${userId}`,
        {},
        true,
        {
          'X-Customer': customerId,
          'X-filter': JSON.stringify(filter),
        },
        download === 'excel' ? true : false
      )
      .then((result) => {
        fileDownloader({
          result,
          filename: 'contact-notes',
          type: download,
          message: message,
        })
      })
  }

  uploadEventRecipients({
    filename,
    applicationevent_key,
    data,
    update_contacts,
    customerId,
    isContactView,
    groupid,
  }) {
    const headers = {
      'X-Customer': customerId,
    }
    let body = {}
    if (!isContactView) {
      body = {
        filename,
        applicationevent_key,
        data,
        update_contacts,
      }
      return this.api
        .put(`service/eventrecipients`, body, true, headers)
        .then((response) => {
          return response
        })
    } else {
      body = {
        filename,
        data,
        groupid,
      }
      return this.api
        .put(`service/contactupload`, body, true, headers)
        .then((response) => {
          return response
        })
    }
  }
}
