import {
  Id,
  TableRequest,
  TableRequestOptions,
  TableResponse,
} from 'app/types/app'
import {
  CleanupEvent,
  CleanupFilter,
  CleanupFilterPost,
  CleanupLogItem,
  CleanupObject,
  CleanupRoutine,
  CleanupRoutinePost,
  CleanupRoutineStatus,
  CleanupRoutineStatusData,
  CleanupValue,
  CleanupValuePost,
} from 'app/types/cleanup'
import { API } from './API'
import { getPageNumber, getTableQueryHeaders } from '.'

export class Cleanup {
  api: API

  constructor(api: API) {
    this.api = api
  }

  getCleanupObjects = async () =>
    this.api.get(`mgmt/systemdatacleanup/objects`) as CleanupObject[]

  createSystemCleanupRoutine = async (data: CleanupRoutinePost) =>
    this.api.post('mgmt/systemdatacleanups', data) as CleanupRoutine

  createPostCustomerCleanupRoutine =
    (customerId: Id) => async (data: CleanupRoutinePost) =>
      this.api.post(
        `mgmt/companies/${customerId}/customerdatacleanups`,
        data
      ) as CleanupRoutine

  updateSystemCleanupRoutine = async (id: Id, data: CleanupRoutinePost) =>
    this.api.put(`mgmt/systemdatacleanup/${id}`, data) as CleanupRoutine

  createUpdateCustomerCleanupRoutine =
    (customerId: Id) => async (id: Id, data: CleanupRoutinePost) =>
      this.api.put(
        `mgmt/companies/${customerId}/customerdatacleanup/${id}`,
        data
      ) as CleanupRoutine

  deleteSystemCleanupRoutine = async (id: Id) =>
    this.api.delete(`mgmt/systemdatacleanup/${id}`)

  createDeleteCustomerCleanupRoutine = (customerId: Id) => async (id: Id) =>
    this.api.delete(`mgmt/companies/${customerId}/customerdatacleanup/${id}`)

  getSystemCleanupRoutineStatus = async (id: Id) =>
    this.api.get(
      `mgmt/systemdatacleanup/${id}/status`
    ) as CleanupRoutineStatusData

  createGetCustomerCleanupRoutineStatus = (customerId: Id) => async (id: Id) =>
    this.api.get(
      `mgmt/companies/${customerId}/customerdatacleanup/${id}/status`
    ) as CleanupRoutineStatusData

  updateSystemCleanupRoutineStatus = async (
    id: Id,
    status: CleanupRoutineStatus
  ) =>
    this.api.put(`mgmt/systemdatacleanup/${id}/status`, {
      status,
    }) as CleanupRoutineStatusData

  createUpdateCustomerCleanupRoutineStatus =
    (customerId: Id) => async (id: Id, status: CleanupRoutineStatus) =>
      this.api.put(
        `mgmt/companies/${customerId}/customerdatacleanup/${id}/status`,
        {
          status,
        }
      ) as CleanupRoutineStatusData

  getSystemCleanupRoutines: TableRequest<CleanupRoutine> = async (options) =>
    this.api.get(
      `mgmt/systemdatacleanups/?page=${getPageNumber(options)}`,
      {},
      true,
      getTableQueryHeaders(options)
    )

  createGetCustomerCleanupRoutines =
    (customerId?: Id) => async (options: TableRequestOptions) => {
      const url = customerId
        ? `mgmt/companies/${customerId}/customerdatacleanups`
        : `mgmt/customerdatacleanups`
      return this.api.get(
        `${url}/?page=${getPageNumber(options)}`,
        {},
        true,
        getTableQueryHeaders(options)
      ) as TableResponse<CleanupRoutine>
    }

  getSystemCleanupRoutine = async (id: Id) =>
    this.api.get(`mgmt/systemdatacleanup/${id}`) as CleanupRoutine

  createGetCustomerCleanup = (customerId: Id) => async (id: Id) =>
    this.api.get(
      `mgmt/companies/${customerId}/customerdatacleanup/${id}`
    ) as CleanupRoutine

  addSystemCleanupFilter = async (cleanupId: Id, data: CleanupFilterPost) =>
    this.api.post(
      `mgmt/systemdatacleanup/${cleanupId}/filters`,
      data
    ) as CleanupFilter

  createAddCustomerCleanupFilter =
    (customerId: Id) => async (cleanupId: Id, data: CleanupFilterPost) =>
      this.api.post(
        `mgmt/companies/${customerId}/customerdatacleanup/${cleanupId}/filters`,
        data
      ) as CleanupFilter

  deleteSystemCleanupFilter = async (id: Id) =>
    this.api.delete(`mgmt/systemdatacleanup/filter/${id}`)

  createDeleteCustomerCleanupFilter = (customerId: Id) => async (id: Id) =>
    this.api.delete(
      `mgmt/companies/${customerId}/customerdatacleanup/filter/${id}`
    )

  setSystemCleanupValue = async (cleanupId: Id, data: CleanupValuePost) =>
    this.api.post(
      `mgmt/systemdatacleanup/${cleanupId}/values`,
      data
    ) as CleanupValue

  createSetCustomerCleanupValue =
    (customerId: Id) => async (cleanupId: Id, data: CleanupValuePost) =>
      this.api.post(
        `mgmt/companies/${customerId}/customerdatacleanup/${cleanupId}/values`,
        data
      ) as CleanupValue

  deleteSystemCleanupValue = async (id: Id) =>
    this.api.delete(`mgmt/systemdatacleanup/value/${id}`)

  createDeleteCustomerCleanupValue = (customerId: Id) => async (id: Id) =>
    this.api.delete(
      `mgmt/companies/${customerId}/customerdatacleanup/value/${id}`
    )

  getSystemCleanupEvents = async (options: TableRequestOptions) =>
    this.api.get(
      `mgmt/systemdatacleanup/statuses/?page=${getPageNumber(options)}`,
      {},
      true,
      getTableQueryHeaders(options)
    )

  getSystemCleanupEventLogs =
    (id: Id) => async (options: TableRequestOptions) =>
      this.api.get(
        `mgmt/systemdatacleanup/status/${id}/logs?page=${getPageNumber(
          options
        )}`,
        {},
        true,
        getTableQueryHeaders(options)
      ) as TableResponse<CleanupLogItem>

  createGetCustomerCleanupEvents =
    (customerId?: Id) => async (options: TableRequestOptions) => {
      const url = customerId
        ? `mgmt/companies/${customerId}/customerdatacleanup/statuses/`
        : `mgmt/customerdatacleanups/statuses/`
      return this.api.get(
        `${url}?page=${getPageNumber(options)}`,
        {},
        true,
        getTableQueryHeaders(options)
      ) as TableResponse<CleanupEvent>
    }

  createGetCustomerCleanupEventLogs =
    (customerId: Id, statusId: Id) => async (options: TableRequestOptions) =>
      this.api.get(
        `mgmt/companies/${customerId}/customerdatacleanup/status/${statusId}/logs?page=${getPageNumber(
          options
        )}`,
        {},
        true,
        getTableQueryHeaders(options)
      ) as TableResponse<CleanupLogItem>
}
