import { get, keys } from 'lodash'
import moment from 'moment'

import { bind } from '../common/utils'
import config from '../config'
import { TOKEN_MAX_AGE } from './API'
import { getPageNumber } from './'
// import { TOKEN_SEPARATOR } from './API';

export const roles = {
  user: 'User',
  admin: 'Admin',
  systemAdmin: 'System admin',
  sysowner: 'System owner',
}

export const serviceRoles = {
  user: 'User',
  admin: 'Admin',
}

export const logMasks = {
  msisdn: 1,
  message: 2,
  contactDetails: 4,
}

/**
 * Get user log mask
 */
export function getUserLogMask(user) {
  return (
    (user.maskmsisdn ? logMasks.msisdn : 0) +
    (user.maskmessage ? logMasks.message : 0) +
    (user.contactdetails ? logMasks.contactDetails : 0)
  )
}

/**
 * User API
 */
export class User {
  constructor(api) {
    this.api = api
    bind(this, [
      'deleteAccount',
      'getTwoFactorSecret',
      'getAccount',
      'getAccounts',
      'getCurrentUser',
      'getCompanies',
      'getAssignedCompanies',
      'getUnassignedCompanies',
      'getInfo',
      'getPolicyGroupAccounts',
      'getSenderList',
      'login',
      'loginTwoFactor',
      'logout',
      'refreshToken',
      'resetPassword',
      'saveAccount',
      'saveCompany',
      'savePolicyGroup',
      'updatePassword',
      'updateProfile',
      'activateAccount',
      'newPassword',
      'activateUserAccount',
      'saveDefaultCustomer',
      'strongPassword',
    ])
  }

  /**
   * Delete an account
   */
  deleteAccount({ id }) {
    return this.api.delete('mgmt/users/' + id).then(() => {
      return {
        success: true,
      }
    })
  }

  async getTwoFactorSecret() {
    return await this.api.get('auth/secret', {}, true, {})
  }

  /**
   * Get single account by ID
   */
  getAccount({ id }) {
    return this.api.get('mgmt/users/' + id).then(async (result) => {
      const role = result.type.toLowerCase()
      return {
        id: result.id + '',
        email: result.user.email,
        username: result.user.username,
        firstName: result.user.first_name,
        lastName: result.user.last_name,
        mobilePhone: result.msisdn || '',
        retriesleft: result.retriesleft,
        is_active: result.user.is_active,
        twoFactorAuthentication: result.twofactor_auth,
        secret: result.twofactor_secret,
        language: result.language,
        mobileNumberToSenderlist: result.contactdetails,
        role: role === 'sysadmin' ? 'systemAdmin' : role,
        logMask: getUserLogMask(result),
        created: moment(result.user.date_joined).toDate(),
        companies: [],
        policyGroups: await this.getPolicyGroups(id),
        userId: result.user.id + '',
        invitation_expires: result?.invitation_expires,
      }
    })
  }

  /**
   * Get accounts
   */
  async getAccounts(options = {}) {
    if (options.search.type === 'systemAdmin') {
      options.search.type = 'sysadmin'
    }
    return this.api.get(
      'mgmt/users?page=' + getPageNumber(options),
      {},
      true,
      this.api.withFilters(options, {
        username: 'username',
        first_name: 'first_name',
        last_name: 'last_name',
        type: 'type',
        is_active: 'is_active',
        msisdn: 'msisdn',
        date_joined: 'date_joined',
        last_login: 'last_login',
      })
    )
  }

  async getPolicyGroupAccounts(options = {}) {
    if (options.search.type === 'systemAdmin') {
      options.search.type = 'sysadmin'
    }
    const { group } = options.extra
    return await this.api.get(
      `mgmt/policies/groups/${group}/users?page=` + getPageNumber(options),
      {},
      true,
      this.api.withFilters(options, {
        username: 'username',
        email: 'email',
        first_name: 'first_name',
        last_name: 'last_name',
        is_active: 'is_active',
        assignment: 'assignment',
        type: 'type',
        date_joined: 'date_joined',
        last_login: 'last_login',
        msisdn: 'msisdn',
      })
    )
  }

  /**
   * Get current user
   */
  getCurrentUser() {
    return this.currentUser || {}
  }

  /**
   * Get user info
   */
  getInfo() {
    const logMasksMap = {
      msisdn: 'maskmsisdn',
      message: 'maskmessage',
      contactDetails: 'contactdetails',
    }
    const roleMap = {
      user: 'user',
      admin: 'admin',
      sysadmin: 'systemAdmin',
      sysowner: 'sysowner',
    }
    return this.api.get('me').then((data) => {
      const { customers = [], user, policies } = data,
        twoFactor = data.twofactor_auth || undefined,
        twoFactorSecret = data.twofactor_secret || undefined,
        ssoKey = data.ssokey || undefined

      return (this.currentUser = {
        customer: {
          customers,
          active:
            customers.find(({ id }) => id === data.default_customer) || null,
          default: data.default_customer || 0,
        },
        username: user.username || '',
        email: user.email || '',
        firstName: user.first_name || '',
        id: user.id + '',
        language: data.language || config.lang.default,
        lastName: user.last_name || '',
        logMask: keys(logMasksMap).reduce(
          (total, key) => total + (data[logMasksMap[key]] ? logMasks[key] : 0),
          0
        ),
        mobilePhone: data.msisdn || '',
        role: roleMap[(data.type || '').toLowerCase()] || 'user',
        twoFactorAuthentication: twoFactor,
        secret: twoFactorSecret,
        policies: policies || {},
        ssoKey,
      })
    })
  }

  getCompanies(options = {}) {
    let newOptions = {
      ...options,
      search: {
        ...options.search,
        user: options.extra.user,
      },
    }

    return this.api
      .get(
        `mgmt/users/customers?page=` + getPageNumber(newOptions),
        {},
        true,
        this.api.withFilters(newOptions, {
          name: 'name',
          vatCode: 'vatcode',
          externalId: 'extid',
          status: 'status',
          demoStatus: 'demo',
          assigned: 'assignment',
          created: 'created',
          user: 'userid',
        })
      )
      .then(({ count, results }) => {
        return {
          count,
          results: results.map((result) => {
            return {
              id: result.id + '',
              name: result.name,
              vatCode: result.vatcode,
              status: result.status.toLowerCase(),
              created: new Date(result.created),
              assignment: result.assignment,
            }
          }),
        }
      })
  }

  getAssignedCompanies(options = {}) {
    return this.getCompanies({
      ...options,
      search: {
        ...options.search,
        assigned: 'ASSIGNED',
      },
    })
  }

  getUnassignedCompanies(options = {}) {
    return this.getCompanies({
      ...options,
      search: {
        ...options.search,
        assigned: 'AVAILABLE',
      },
    })
  }

  /**
   * Get User Policygroups
   *
   * @param {integer} id
   */
  getPolicyGroups(id) {
    return this.api.get(`mgmt/users/${id}/policygroups`).then(({ results }) => {
      return results
        .filter(({ assignment }) => {
          return assignment === 'ASSIGNED'
        })
        .map(({ id }) => id + '') // id is string
    })
  }

  /**
   * Get sender list
   */
  async getSenderList({ customerId }) {
    return (
      await this.api.get('service/senderlistsraw', {}, true, {
        'X-Customer': customerId,
      })
    ).map((sender) => ({
      id: sender.id,
      value: sender.sender,
      type: sender.sender_type,
    }))
  }

  /**
   * Save sender list
   */
  async saveSenderList({ sender, customerId }) {
    return await this.api.post(
      'service/senderlist',
      {
        sender,
      },
      true,
      {
        'X-Customer': customerId,
      }
    )
  }
  /**
   * Save sender list
   */
  async deleteSenderList({ id, customerId }) {
    return await this.api.delete('service/senderlist/' + id, {}, true, {
      'X-Customer': customerId,
    })
  }

  /**
   * Login user
   */
  login({ email, password }) {
    // destory token if any
    this.api.destroyToken()

    if (!email) {
      return Promise.reject('Email is required')
    }
    if (!password) {
      return Promise.reject('Password is required')
    }
    return this.api
      .post('auth/login', {
        username: email,
        password,
      })
      .then((response) => {
        if (!response.twofactorAuth) {
          return this.api.setToken(response.access, response.refresh).store()
            .token
        } else {
          return {
            auth: response.twofactorAuth,
            timeout: response.twofactorTimeout,
            verify: response.twofactorVerify,
          }
        }
      })
      .catch((error) => {
        return Promise.reject(error.response.data.details)
      })
  }

  loginViaSso(
    key,
    {
      id_token,
      access_token,
      token_type,
      scope,
      state,
      session_state,
      expires_in,
      error,
      error_description,
    }
  ) {
    // destory token if any
    this.api.destroyToken()

    let payload = {
      id_token,
      access_token,
      token_type,
      scope,
      state,
      session_state,
      expires_in,
    }

    if (error) {
      payload['error'] = error
    }

    if (error_description) {
      payload['error_description'] = error_description
    }

    return this.api
      .post(`auth/sso/${key}/login/`, payload)
      .then((response) => {
        if (response.access && response.refresh) {
          this.api.cookies.set('ssokey', key, {
            maxAge: TOKEN_MAX_AGE,
            path: '/',
          })

          return this.api.setToken(response.access, response.refresh).store()
            .token
        } else {
          return {
            info: response.info,
          }
        }
      })
      .catch((error) => {
        return Promise.reject('Access denied')
      })
  }

  /**
   * Two factor login
   */
  loginTwoFactor({ auth, code, timeout, verify }) {
    if (!code) {
      return Promise.reject('Code is required')
    }
    return this.api
      .post('auth/login/twofactor', {
        twofactorAuth: auth,
        twofactorVerify: verify,
        twofactorTimeout: timeout,
        code: code,
      })
      .then((response) => {
        return this.api.setToken(response.access, response.refresh).store()
          .token
      })
      .catch((error) => {
        return Promise.reject('Invalid code please try again')
      })
  }

  /**
   * Logout user
   */
  logout({ redirect, path }) {
    return this.api
      .delete(`auth/logout/${this.api.refresh}`)
      .catch(() => this)
      .then(() => {
        this.currentUser = null
        this.api.destroyToken()
        return redirect(path)
      })
  }

  /**
   * Refresh token
   */
  refreshToken() {
    const { refresh } = this.api
    if (!this.api.token || !refresh) {
      return Promise.resolve(this)
    }
    return this.api
      .post('auth/refresh', { refresh })
      .then(({ access, policies, policygroups, customers }) => {
        this.api.setToken(access, refresh).store()
        return {
          customers,
          policies,
          policygroups,
        }
      })
  }

  /**
   * Reset password
   */
  resetPassword({ email }) {
    if (!email) {
      return Promise.reject('Email is required')
    }
    return this.api
      .post('auth/passwordreset', { email })
      .catch(() => null)
      .then(() => {
        return {
          success: true,
        }
      })
  }

  /**
   * Save account
   */
  saveAccount(account) {
    const role = {
      user: 'USER',
      admin: 'ADMIN',
    }[account.role]
    if (account.userId) {
      return this.api
        .put('mgmt/users/' + account.userId, {
          id: parseInt(account.userId),
          msisdn: account.mobilePhone,
          type: role,
          language: account.language,
          maskmessage: !!(account.logMask & logMasks.message),
          maskmsisdn: !!(account.logMask & logMasks.msisdn),
          contactdetails: !!(account.logMask & logMasks.contactDetails),
          retriesleft: account.retriesleft,
          twofactor_auth: account.twoFactorAuthentication,
          twofactor_secret: account.secret,
          user: {
            id: parseInt(account.userId),
            username: account.username,
            email: account.username,
            is_active: account.is_active,
            first_name: account.firstName,
            last_name: account.lastName,
          },
          assign_customers: account.assignCustomers,
          unassign_customers: account.unassignCustomers,
        })
        .then((result) => {
          return {
            id: result.id + '',
            firstName: result.user.first_name,
            lastName: result.user.last_name,
            retriesleft: result.retriesleft,
            twoFactorAuthentication: result.twofactor_auth,
            secret: result.twofactor_secret,
            is_active: result.user.is_active,
            userId: result.user.id + '',
          }
        })
    } else {
      return this.api
        .post('mgmt/users', {
          id: 0,
          msisdn: account.mobilePhone,
          type: role,
          language: account.language,
          maskmessage: !!(account.logMask & logMasks.message),
          maskmsisdn: !!(account.logMask & logMasks.msisdn),
          contactdetails: !!(account.logMask & logMasks.contactDetails),
          retriesleft: account.retriesleft,
          twofactor_auth: account.twoFactorAuthentication,
          twofactor_secret: account.secret,
          user: {
            id: 0,
            password: account.password,
            username: account.username,
            email: account.username,
            is_active: account.is_active,
            first_name: account.firstName,
            last_name: account.lastName,
          },
          assign_customers: account.assignCustomers,
        })
        .then((result) => {
          return {
            id: result.id + '',
            username: result.user.username,
            email: result.user.email,
            firstName: result.user.first_name,
            lastName: result.user.last_name,
            mobilePhone: result.msisdn,
            retriesleft: result.retriesleft,
            twoFactorAuthentication: result.twofactor_auth,
            secret: result.twofactor_secret,
            language: result.language,
            mobileNumberToSenderlist: result.contactdetails,
            role: role === 'sysadmin' ? 'systemAdmin' : role.toLowerCase(),
            logMask: getUserLogMask(result),
            new: true,
            is_active: result.user.is_active,
            userId: result.user.id + '',
          }
        })
    }
  }

  /**
   * Save company
   */
  saveCompany({ user, company, request, successCallback }) {
    return this.api
      .put(`mgmt/users/${user}/customers`, {
        ...request,
        id: company,
      })
      .then(() => successCallback())
  }

  /**
   * Save policy group
   */
  savePolicyGroup({ group, toggle, user, successCallback }) {
    const assignment = {
      true: 'ASSIGNED',
      false: 'AVAILABLE',
    }
    return this.api
      .put(`mgmt/users/${user}/policygroups`, {
        id: group,
        assignment: assignment[toggle],
      })
      .then((result) => {
        successCallback()
        return result
      })
  }

  /**
   * Update password
   */
  async updatePassword({ password, old, retype }) {
    if (!password || !old) {
      return Promise.reject('Password is required')
    }
    return this.api
      .post('me/changepassword', {
        old_password: old,
        new_password1: password,
        new_password2: retype,
      })
      .then((result) => {
        return {
          info: result.info,
          success: true,
        }
      })
      .catch((error) => {
        return Promise.reject(error.response.data.new_password2)
      })
  }

  /**
   * Update profile
   */
  async updateProfile(account) {
    const role = {
      user: 'USER',
      admin: 'ADMIN',
      systemAdmin: 'SYSADMIN',
      sysowner: 'SYSOWNER',
    }[account.role]
    const result = await this.api.put('me', {
      id: parseInt(account.id),
      msisdn: account.mobilePhone,
      type: role,
      language: account.language,
      maskmessage: !!(account.logMask & logMasks.message),
      maskmsisdn: !!(account.logMask & logMasks.msisdn),
      contactdetails: !!(account.logMask & logMasks.contactDetails),
      twofactor_auth: account.twoFactorAuthentication,
      twofactor_secret: account.secret,
      user: {
        id: parseInt(account.id),
        username: account.username,
        email: account.username,
        first_name: account.firstName,
        last_name: account.lastName,
      },
    })
    try {
      if (account.mobilePhone && get(account, 'customer.active.id')) {
        const msisdn = (account.senderList || []).find(
          (item) => (item.type || '').toUpperCase() === 'MSISDN'
        )
        if (msisdn && msisdn.id) {
          await this.api.delete('service/senderlist/' + msisdn.id, {}, true, {
            'X-Customer': account.customer.active.id,
          })
        }
        if (account.mobileNumberToSenderlist === true) {
          await this.api.post(
            'service/senderlist',
            {
              sender: account.mobilePhone,
              sender_type: 'MSISDN',
            },
            true,
            {
              'X-Customer': account.customer.active.id,
            }
          )
        }
      }
    } catch (exception) {
      // Do nothing
    }
    return {
      id: result.id + '',
      username: result.user.username,
      email: result.user.email,
      firstName: result.user.first_name,
      lastName: result.user.last_name,
      mobilePhone: result.msisdn,
      twoFactorAuthentication: result.twofactor_auth,
      secret: result.twofactor_secret,
      language: result.language,
      mobileNumberToSenderlist: result.contactdetails,
      userId: result.user.id + '',
    }
  }

  activateAccount({ customerId, body }) {
    return this.api.post(`service/activateaccount`, body, true, {
      'X-Customer': customerId,
    })
  }

  /**
   * New password
   */
  newPassword({ password, retype, token }) {
    if (!password || !retype) {
      return Promise.reject('Password is required')
    }
    if (password !== retype) {
      return Promise.reject('Passwords are not the same')
    }
    return this.api
      .post('auth/passwordreset/' + token + '/', {
        new_password1: password,
        new_password2: retype,
      })
      .then(() => {
        return {
          success: true,
        }
      })
      .catch((error) => {
        return Promise.reject(error.response.data.validation)
      })
  }

  /**
   * Activate account
   */
  activateUserAccount({ password, retype, token, activation_code }) {
    if (!activation_code) {
      return Promise.reject('Activation code is required')
    }
    if (!password || !retype || !activation_code) {
      return Promise.reject('Password is required')
    }
    if (password !== retype) {
      return Promise.reject('Passwords are not the same')
    }
    return this.api
      .post('auth/invitationconfirm/' + token + '/', {
        activation_code: activation_code,
        new_password1: password,
        new_password2: retype,
      })
      .then(() => {
        return {
          success: true,
        }
      })
      .catch((error) => {
        return Promise.reject(error.response.data.validation)
      })
  }

  /**
   * Changing default customer
   */
  saveDefaultCustomer(payload) {
    return this.api
      .put('me/defaultcustomer', {
        default_customer: payload.defaultCustomer,
      })
      .then(() => {
        return {
          success: true,
        }
      })
      .catch((error) => {
        return Promise.reject('Default customer change failed')
      })
  }

  /**
   * Get strong password
   */
  strongPassword() {
    return this.api.get('mgmt/strongpassword').then(({ password }) => {
      return password
    })
  }

  sendInvitation(id) {
    return this.api.get(`mgmt/users/${id}/invite`).then(({ expires }) => ({
      expires,
    }))
  }
}
