import moment from 'moment'

import { bind } from '../common/utils'
import { getPageNumber } from './'

export class NewsFeed {
  constructor(api) {
    this.api = api
    bind(this, [
      'getNewsFeeds',
      'getNewsFeed',
      'getArticle',
      'deleteArticle',
      'saveArticle',
    ])
  }

  getNewsFeeds(request = {}) {
    const options = {
      ...request,
      search: {
        ...request.search,
      },
    }
    return this.api
      .get('mgmt/newsfeed?page=' + getPageNumber(options), {}, true, {
        ...this.api.withFilters(options, {
          created: 'created',
          status: 'published',
          title: 'title',
          description: 'description',
        }),
      })
      .then(({ count, results }) => {
        return {
          count,
          results: results.map((result) => {
            return {
              id: result.id,
              status: result.published,
              author: result.author,
              title: result.title,
              description: result.description,
              content: result.text_body,
              created: result.created,
            }
          }),
        }
      })
  }

  getNewsFeed(request) {
    const { customerId } = request
    return this.api
      .get('service/newsfeed', {}, true, {
        'X-Customer': customerId,
      })
      .then((results) => {
        return {
          data: results.map((result) => {
            return {
              title: result.title,
              content: JSON.parse(result.text_body),
              description: result.description,
              created: result.created,
              id: result.id,
            }
          }),
        }
      })
      .catch((error) => {
        return Promise.reject('No news')
      })
  }

  getArticle(request) {
    return this.api
      .get(`mgmt/newsfeed/${request.id}`, {}, true, {})
      .then((result) => {
        return {
          resultid: result.id,
          title: result.title,
          created: result.created,
          editorState: JSON.parse(result.text_body),
          status: result.published,
          description: result.description,
        }
      })
  }

  deleteArticle({ id }) {
    return this.api.delete(`mgmt/newsfeed/${id}`).then(() => {
      return {
        success: true,
      }
    })
  }

  async saveArticle(article) {
    if (article.action === 'add') {
      return await this.api
        .post('mgmt/newsfeed', {
          title: article.title,
          created: moment(),
          text_body: article.editorState,
          published: article.status,
          description: article.description,
        })
        .then((result) => {
          return {
            id: result.id,
            title: result.title,
            created: result.created,
            editorState: JSON.parse(result.text_body),
            status: result.published,
            description: result.description,
          }
        })
    } else {
      return await this.api
        .put('mgmt/newsfeed/' + article.action, {
          title: article.title,
          text_body: article.editorState,
          published: article.status,
          description: article.description,
        })
        .then((result) => {
          return {
            id: result.id,
            title: result.title,
            created: result.created,
            editorState: JSON.parse(result.text_body),
            status: result.published,
            description: result.description,
          }
        })
    }
  }
}
