import { extend } from 'lodash'
import { handleActions } from 'redux-actions'

import { ComponentStatus } from 'app/types/app'

/**
 * Configurations initial state
 */
export const configurationsState = {
  data: {},
  dataCleanupObjects: [],
  dataCleanupObjectsState: ComponentStatus.IDLE,
  dataCleanupOptions: {
    chunk_sizes: [10, 100, 1000],
    default_chunk_size: 100,
    default_begin_earliest_time: '02:00',
    default_end_latest_time: '06:00',
    intervalOptions: {
      five_minutes: {
        label: 'Every 5 minutes',
        interval: 'minutes',
        amount: 5,
      },
      one_hour: {
        label: 'Every hour',
        interval: 'hours',
        amount: 1,
      },
      one_day: {
        label: 'Every day',
        interval: 'days',
        amount: 1,
      },
    },
    default_interval: 'one_day',
  },
}

/**
 * Configurations reducer
 */
export default handleActions(
  {
    CONFIGURATIONS_RESOURCES: (state, { payload }) => {
      return extend({}, state, {
        data: extend({}, state.data, payload || {}),
      })
    },
    CONFIGURATIONS_RESOURCES_SUCCESS: (state, { payload }) => {
      return extend({}, state, {
        data: extend({}, state.data, payload || {}),
      })
    },
    CONFIGURATIONS_RESOURCES_FAIL: (state, { payload }) => {
      return {
        ...state,
      }
    },
    CONFIGURATIONS_RESOURCES_RELOAD_SUCCESS: (state, { payload }) => {
      return extend({}, state, {
        data: extend({}, state.data, payload || {}),
      })
    },
    CONFIGURATIONS_RESET: (state) => {
      return extend({}, state, {
        data: {},
      })
    },
    CLEANUP_OBJECTS: (state) => {
      return extend({}, state, {
        dataCleanupObjectsState: ComponentStatus.LOADING,
      })
    },
    CLEANUP_OBJECTS_SUCCESS: (state, { payload }) => {
      return extend({}, state, {
        dataCleanupObjects: payload,
        dataCleanupObjectsState: ComponentStatus.IDLE,
      })
    },
    CLEANUP_OBJECTS_FAIL: (state) => {
      return extend({}, state, {
        dataCleanupObjects: [],
        dataCleanupObjectsState: ComponentStatus.IDLE,
      })
    },
  },
  configurationsState
)
