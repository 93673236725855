import { ReactNode } from 'react'
import { omit } from 'lodash'

import { routingSourceTypes } from 'app/api/Router'
import { BreadcrumbItem } from 'app/home/Home'

export interface Application {
  id: number
  modulename: string
  name: string
  created: string
  short_description: string
  channels: string
  icon: string
  applicationevent_key: string
}

export interface SystemApplication extends Application {
  apikeys: string[]
  default_template?: {
    id: number
    message: string
  }
}

export interface UiApplication {
  id: number
  active: boolean
  description: string
  name: string
  title: string
  icon: string
  tags: any[]
}

export enum ApplicationAction {
  CREATE = 'create',
  EDIT = 'edit',
  COPY = 'copy',
}

export enum ComponentStatus {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
}

export enum MessageTemplateType {
  GENERAL = 'GENERAL',
  VERIFY = 'VERIFY',
}

export enum ApiKeyStatus {
  ACTIVE = 'ACTIVE',
  PASSIVE = 'PASSIVE',
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export enum BillingDataStatus {
  CREATED = 'CREATED',
  TRANSFERRED = 'TRANSFERRED',
  ARCHIVED = 'ARCHIVED',
  INVALID_DATA = 'INVALID_DATA',
  SEND_ERROR = 'SEND_ERROR',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export enum InvoiceStatus {
  CREATED = 'CREATED',
  BILLED = 'BILLED',
  FINISHED = 'FINISHED',
  AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED',
  DUPLICATE_DATA = 'DUPLICATE_DATA',
  REQUEST_NOT_UNIQUE = 'REQUEST_NOT_UNIQUE',
  SERVICE_ACCESS_ERROR = 'SERVICE_ACCESS_ERROR',
  SYSTEM_MAINTENANCE = 'SYSTEM_MAINTENANCE',
  TECHNICAL_ERROR = 'TECHNICAL_ERROR',
  PERIOD_LOCK = 'PERIOD_LOCK',
  INVALID_DATA = 'INVALID_DATA',
  UNKNOWN_ERROR = 'GENERAL_ERROR',
}

export type Direction = 'MO' | 'MT'

export interface MessageTemplate {
  id?: number
  apiuse: boolean
  name: string
  message: string
  type: MessageTemplateType
  created?: string
  default: boolean
}

export interface VerifyMessage {
  id?: number
  message: string
}

export enum DateResolution {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
  YEARLY = 'Yearly',
}

export interface FlowStatistics<T> {
  total_messages: number
  chart_data: {
    xkey: string
    data: T[]
  }
}

export interface LineChartLabel<T> {
  label: string
  color: string
  key: keyof T
}

export interface LineChartSeries {
  name: string
  type: 'line'
  data: number[]
  itemStyle: {
    color: string
  }
  lineStyle: {
    color: string
  }
}

export type LineChartData = {
  data: {
    labels: string[]
    series: LineChartSeries[]
  }
}

export type PieChartItem = {
  value: number
  name: string
}

export type PieChartData = {
  data: PieChartItem[]
  colors: string[]
}

export type ValueOf<T> = T[keyof T]
export interface VerifyMessage {
  message: string
}

const srcOptions = omit(routingSourceTypes, 'SRC_APPLICATION')

export type SourceOption = keyof typeof srcOptions

export type AllowedApp = Pick<Application, 'id' | 'modulename'>

export interface ApiKeyPostData {
  name: string
}

export interface ApiKey extends ApiKeyPostData {
  id: number
  apikey: string
  status: ApiKeyStatus
  expires: string | null
  created: string
  description: string
  allowed_app: AllowedApp[]
  allowed_src: SourceOption[]
}

export interface ApiKeyPutData extends Omit<ApiKey, 'apikey'> {
  apikey: string | null
}

export enum SinglePurposeApiKeyType {
  APPLICATION = 'APPLICATION',
  ROUTER = 'ROUTER',
}

export interface SinglePurposeApiKey {
  apikey: string
  name: string
  modulename: string
  type: SinglePurposeApiKeyType
  used_in: number
}

export interface TableResponse<T> {
  count: number
  next: string | null
  previous: string | null
  results: T[]
}

export interface TableRequestOptions {
  customerId?: number
  limit?: number
  offset?: number
  filters?: TableRequestFilters
}

export interface TableRequestFilters {
  sorted?: `${string}:${SortOrder}`
  page_size: number
  search: SearchParams
}

export type TableRequest<T> = (
  options: TableRequestOptions
) => Promise<TableResponse<T>>

export interface SortParams {
  key: string
  order: SortOrder
}

export type SearchParams = {
  [P in string]?: string | number
}

export type StringKey<T> = keyof T & string

export type FormErrors = {
  [key: string]: string | string[]
}

export type TimeWithoutSeconds = `${number}:${number}`
export type TimeWithSeconds = `${number}:${number}:${number}`
export type Date = `${number}-${number}-${number}`
export type DateAndTime = `${Date}T${TimeWithSeconds}`

export type WithSetBreadcrumb<T = {}> = T & {
  setBreadcrumb?: (items: BreadcrumbItem[]) => ReactNode
}

export type Id = string | number

export interface Price {
  id?: number
  currency?: string
  product?: number
  price: number
  price_vat: number
  pricing_type?: PricingType
  valid_from?: string
  current?: boolean
}

export enum PricingType {
  PRICE = 'PRICE',
  PURCHASE = 'PURCHASE',
  CUSTOMPRICE = 'CUSTOMPRICE',
}

export enum BillingType {
  ONCE = 'ONCE',
  RECURRING = 'RECURRING',
  TRANSACTION = 'TRANSACTION',
}

export enum TransactionType {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  RCS = 'RCS',
  SUCCESSFUL_VERIFY = 'SUCCESSFUL_VERIFY',
}

export interface BillingItem {
  id: number
  amount: number
  billing_ref: string
  created: string
  customer_id: number
  customer_name: string
  ext_id: string
  free_text: string
  price: number
  product_billing_type: BillingType
  product_code: string
  product_currency: string
  product_name: string
  product_id: number
  purchase_price: number
  purchase_vat: number
  status: string
  value_datetime: string
  vat: number
  total_sum: number
  total_sum_incl_vat: number
}

export interface InvoiceItem
  extends Omit<
    BillingItem,
    'customer_id' | 'customer_name' | 'ext_id' | 'status'
  > {
  billing_info: string
  customer_billing_ref: string
  customer_invoice_id: number
}

export interface Invoice {
  id: number
  billing_date: string
  billing_interval: number
  created: string
  customer_id: number
  customer_name: string
  ext_id: string
  status: string
  total_sum: number
  total_sum_incl_vat: number
  vat_code: string
  payment_term: number
  our_billing_ref?: string
  your_billing_ref?: string
}

export enum Currency {
  EUR = 'EUR',
}

export interface ProductGroup {
  id: number
  name: string
  description?: string
  created?: string
  modified?: string
}

export interface SMSTransactionBilling {
  id?: number
  direction: Direction
  created?: string
  modified?: string
  operator: string | null
  countrycode: string | null
  product_id?: number
}

export interface Resources {
  httpCommonApi: string
  httpApiDocs?: string
  smsApi: string
  groupsApi: string
  appApi: string
  smsHttpApi: string
  smsEmailApi: string
  smsHttpPullApi: string
  firewallsIps: string
  infoPricingUrl: string
}

export function getTypedKeys<T extends object>(item: T): (keyof T)[] {
  return Object.keys(item) as (keyof T)[]
}

// URL param types
export interface IdParams {
  id: string
  [k: string]: string | undefined
}

export interface Customer {
  id: number
  name: string
  licensecustomer: boolean
  vatcode: string
  extid: string
  status: string
  demo: string
  mtlimit: number
  filtering: boolean
  sender: string
  sendertype: string
  country_code: number
  maskmsisdn: boolean
  maskmessage: boolean
  throttle: number
  validityperiod: number
  info: string
  created: string
  delete_grace_period: string | null
}
